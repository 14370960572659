import { Expose, Type } from 'class-transformer';
import { HubEntity } from '../hub';

export class TrackingCodeEntity {
  @Expose()
  @Type(() => HubEntity)
  hub: HubEntity;

  @Expose()
  trackingCode: string;
}
