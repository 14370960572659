import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { MediaModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/media',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    getMedia: builder.query<MediaModel, string>({
      query: (uuid) => uuid,
      keepUnusedDataFor: 0,
    }),
    deleteMedia: builder.query<MediaModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: uuid }),
    }),
  }),
});

export const { useGetMediaQuery, useLazyGetMediaQuery, useLazyDeleteMediaQuery } = mediaApi;
