import { Expose, Type } from 'class-transformer';
import { ImporterEntity } from './importer.entity';

export class ImporterJobEntity {
  @Expose()
  uuid?: string;

  @Expose()
  @Type(() => ImporterEntity)
  importer: ImporterEntity;

  @Expose()
  result: string;

  @Expose()
  error: boolean;
}
