import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { OrganisationEntity } from '../organisation';
import { UserEntity } from '../user';
import { MediaMetaEntity, MediaPartEntity } from './media-meta.entity';
import type { OrganisationEntity as OrganisationEntityType } from '../organisation';
import type { UserEntity as UserEntityType } from '../user';
import type { MediaMetaEntity as MediaMetaEntityType } from './media-meta.entity';

export class MediaEntity {
  @Expose()
  uuid?: string;

  @Expose()
  originalName?: string;

  @Expose()
  s3Url?: string;

  @Expose()
  sourceUrl?: string;

  @Expose()
  @Type(() => MediaMetaEntity)
  meta: MediaMetaEntityType;

  @Expose()
  @Type(() => UserEntity)
  user: UserEntityType;

  @Expose()
  @Type(() => OrganisationEntity)
  organisation: OrganisationEntityType;

  @Expose()
  createdAt?: Date;

  @Expose()
  @Type(() => MediaPartEntity)
  parts?: Array<MediaPartEntity>;
}

export class ExternalMediaPublishedEntity extends MediaEntity {
  @Exclude()
  user: any;

  @Exclude()
  organisation: any;

  @Exclude()
  sourceUrl?: string;

  @Exclude()
  s3Url?: string;

  @Expose()
  url?: string;

  @Exclude()
  meta: MediaMetaEntity;

  @Exclude()
  parts?: MediaPartEntity[];

  @Expose()
  mime?: string;

  @Expose()
  filename?: string;
}
