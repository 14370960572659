import { Expose } from 'class-transformer';

export class DocumentHashDto {
  @Expose()
  uuid?: string;

  @Expose()
  hash: string;

  @Expose()
  contentHash: string;

  @Expose()
  documentUuid: string;
}
