import { PolicyAction, PolicySubject } from '@nccore/shared';
import { isEmpty, isFunction } from 'lodash';
import { useState } from 'react';
import AppButton from './AppButton';
import AppConfirmDialog from './AppConfirmDialog';
import { cilTrash } from '@coreui/icons';

interface AppConfirmButton {
  onConfirm: () => void;
  onDismiss?: () => void;
  buttonColor?: string;
  buttonText?: string;
  buttonIcon?: string[];
  modalTitle: string;
  modalBody: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  size?: 'sm' | 'lg';
  policySubject?: PolicySubject;
  policyAction?: PolicyAction | PolicyAction[];
  type?: 'create' | 'save' | 'delete';
}

const AppConfirmButton = (props: AppConfirmButton) => {
  const {
    onConfirm,
    onDismiss,
    buttonText,
    buttonIcon,
    buttonColor,
    modalTitle,
    modalBody,
    className,
    disabled,
    size,
    policySubject,
    policyAction,
    type,
    isLoading,
  } = props;
  const [dialogVisible, setDialogVisible] = useState(false);

  const onDialogConfirm = () => {
    setDialogVisible(false);
    onConfirm();
  };

  const onDialogDismiss = () => {
    setDialogVisible(false);
    if (isFunction(onDismiss)) {
      onDismiss();
    }
  };

  return (
    <span className={className}>
      <AppButton
        icon={buttonIcon}
        title={buttonText}
        type={type}
        color={!isEmpty(buttonColor) ? buttonColor : 'primary'}
        onClick={(e) => setDialogVisible(true)}
        disabled={disabled}
        size={size}
        policyAction={policyAction}
        policySubject={policySubject}
        isLoading={isLoading}
      />
      <AppConfirmDialog message={modalBody} onConfirm={onDialogConfirm} onDismiss={onDialogDismiss} visible={dialogVisible} title={modalTitle} />
    </span>
  );
};
export default AppConfirmButton;
