import { Exclude, Expose, Type } from 'class-transformer';
import { OrganisationEntity } from '../organisation';
import { DocumentTemplateFilterEntity } from './documenttemplatefilter.entity';
import { DocumentTemplateSlotEntity } from './documenttemplateslot.entity';
import { AuthorEntity } from '../author';

export class DocumentTemplateEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  description: string;

  @Expose()
  @Type(() => DocumentTemplateSlotEntity)
  slots: DocumentTemplateSlotEntity[];

  @Expose()
  @Type(() => DocumentTemplateFilterEntity)
  filter: DocumentTemplateFilterEntity[];

  @Expose()
  @Type(() => OrganisationEntity)
  organisation: OrganisationEntity;

  @Expose()
  @Type(() => AuthorEntity)
  author?: AuthorEntity;

  @Expose()
  deletedAt?: Date;
}

export class ExternalDocumentTemplateEntity extends DocumentTemplateEntity {
  @Exclude()
  slots: DocumentTemplateSlotEntity[];

  @Exclude()
  filter: DocumentTemplateFilterEntity[];

  @Exclude()
  organisation: OrganisationEntity;

  @Exclude()
  deletedAt?: Date;
}
