import React from 'react';
import { CFooter, CLink } from '@coreui/react';
import moment from 'moment';
import versionFile from '../assets/version.json';
import { useNavigate } from 'react-router-dom';

const AppFooter = () => {
  const year = moment().format('YYYY');
  const navigate = useNavigate();

  return (
    <CFooter className="mt-4">
      <div style={{ textAlign: 'center', width: '100%' }}>
        newscube{' '}
        <CLink
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate('/changelog');
          }}
        >
          v{versionFile.version}
        </CLink>
        <span className="ms-1">&copy; {year} adorum publishing GmbH</span> -{' '}
        <a href="https://status.newscube.de/" target="_blank" style={{ color: 'rgba(44, 56, 74, 0.95)' }}>
          Status
        </a>{' '}
        -{' '}
        <a href="/datenschutz" target="_blank" style={{ color: 'rgba(44, 56, 74, 0.95)' }}>
          Datenschutzerkl&auml;rung
        </a>
      </div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
