import CIcon from '@coreui/icons-react';
import {
  cilChart,
  cilImage,
  cilLibrary,
  cilBuilding,
  cilGroup,
  cilSettings,
  cilSpeedometer,
  cilSitemap,
  cilBrowser,
  cilExternalLink,
  cilFork,
  cilUser,
  cilTags,
  cilFilter,
  cilCloudUpload,
  cilGrid,
  cilAddressBook,
  cilDescription,
} from '@coreui/icons';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';
import { PolicyAction, PolicySubject } from '@nccore/shared';

export interface NavInterface {
  component: any;
  translationKey: string;
  to?: string;
  icon?: any;
  end?: boolean;
  badge?: {
    color: string;
    text: string;
  };
  items?: NavInterface[];
  ability?: {
    subject: PolicySubject;
    action: PolicyAction;
  };
  className?: string;
}

const _nav: NavInterface[] = [
  {
    component: CNavItem,
    translationKey: 'dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    ability: { subject: PolicySubject.Dashboard, action: PolicyAction.Read },
    className: 'nav-dashboard',
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavItem,
    translationKey: 'editor',
    to: '/editor',
    icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
    ability: { subject: PolicySubject.Document, action: PolicyAction.Read },
    className: 'nav-editor',
  },
  {
    component: CNavItem,
    translationKey: 'links',
    to: '/links',
    icon: <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
    ability: { subject: PolicySubject.Link, action: PolicyAction.Read },
    className: 'nav-links',
  },
  {
    component: CNavItem,
    translationKey: 'adbox',
    to: '/adbox',
    icon: <CIcon icon={cilBrowser} customClassName="nav-icon" />,
    ability: { subject: PolicySubject.Adbox, action: PolicyAction.Read },
    className: 'nav-adbox',
  },
  {
    component: CNavItem,
    translationKey: 'media',
    to: '/media',
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
    ability: { subject: PolicySubject.Media, action: PolicyAction.Read },
    className: 'nav-media',
  },
  {
    component: CNavGroup,
    translationKey: 'statistics.title',
    to: '/stats',
    icon: <CIcon icon={cilChart} customClassName="nav-icon" />,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
    className: 'nav-stats',
    items: [
      {
        component: CNavItem,
        translationKey: 'statistics.dashboard',
        to: '/stats',
        icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
        end: true,
      },
      {
        component: CNavItem,
        translationKey: 'statistics.hubs',
        to: '/stats/hubs',
        icon: <CIcon icon={cilSitemap} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        translationKey: 'statistics.streams',
        to: '/stats/streams',
        icon: <CIcon icon={cilGrid} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        translationKey: 'statistics.documents',
        to: '/stats/documents',
        icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        translationKey: 'statistics.submitters',
        to: '/stats/submitters',
        icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.StatsHub, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'statistics.links',
        to: '/stats/links',
        icon: <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavTitle,
    translationKey: 'system',
  },
  {
    component: CNavGroup,
    translationKey: 'settings.title',
    to: '/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    className: 'nav-settings',
    items: [
      {
        component: CNavItem,
        translationKey: 'settings.organisations',
        to: '/settings/organisation',
        icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.Organisation, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.hubs',
        to: '/settings/hub',
        icon: <CIcon icon={cilSitemap} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.Hub, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.users',
        to: '/settings/user',
        icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.User, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.roles',
        to: '/settings/role',
        icon: <CIcon icon={cilFork} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.Role, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.adbox-templates',
        to: '/settings/adboxtemplate',
        icon: <CIcon icon={cilBrowser} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.AdboxTemplate, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.authors',
        to: '/settings/author',
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.Author, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.templates',
        to: '/settings/documenttemplate',
        icon: <CIcon icon={cilBrowser} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.DocumentTemplate, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.layouts',
        to: '/settings/documentlayout',
        icon: <CIcon icon={cilBrowser} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.DocumentLayout, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.tags',
        to: '/settings/tag',
        icon: <CIcon icon={cilTags} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.Tag, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.input-filters',
        to: '/settings/inputfilter',
        icon: <CIcon icon={cilFilter} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.InputFilter, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.importer',
        to: '/settings/importer',
        icon: <CIcon icon={cilCloudUpload} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.Importer, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.settings',
        to: '/settings/config',
        icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
        ability: { subject: PolicySubject.Config, action: PolicyAction.Read },
      },
      {
        component: CNavItem,
        translationKey: 'settings.account',
        to: '/settings/',
        icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
        // ability: { subject: PolicySubject.Settings, action: PolicyAction.Read },
      },
      // {
      //   component: CNavItem,
      //   name: 'Cube',
      //   to: '/cube',
      //   icon: <CIcon icon={cibNodeJs} customClassName="nav-icon" />,
      // },
    ],
  },
  {
    component: CNavItem,
    translationKey: 'changelog',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    to: '/changelog',
  },
];

export default _nav;
