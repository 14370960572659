import { Expose, Type } from 'class-transformer';
import { HubEntity } from '../hub';
import { StreamEntity } from '../stream';
import { ImporterEntity } from '../importer';

export class WebhookEntity {
  @Expose()
  uuid?: string;

  @Expose()
  @Type(() => StreamEntity)
  streams?: StreamEntity[];

  @Expose()
  @Type(() => ImporterEntity)
  importer: ImporterEntity;

  @Expose()
  response: string;
}
