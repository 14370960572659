import React from 'react';
import { useLocation } from 'react-router-dom';
import { matchRoutes, useNavigate } from 'react-router';

import routes from '../routes';

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const AppBreadcrumb = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation().pathname;
  const { t } = useTranslation('breadcrumb');

  const getRouteName = (pathname: string, routes: any) => {
    const matchedRoutes = matchRoutes(routes, pathname);
    if (isEmpty(matchedRoutes)) {
      return null;
    }

    const route = matchedRoutes[0].route;
    // @ts-ignore
    return t(route.translationKey);
  };

  const getBreadcrumbs = (location: string) => {
    const breadcrumbs: any[] = [];
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      breadcrumbs.push({
        pathname: currentPathname,
        name: getRouteName(currentPathname, routes),
        active: index + 1 === array.length ? true : false,
      });
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem
        href="/"
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
      >
        Home
      </CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
            key={index}
            onClick={(e) => {
              e.preventDefault();
              navigate(breadcrumb.pathname);
            }}
          >
            {breadcrumb.name}
          </CBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
};

export default React.memo(AppBreadcrumb);
