import { useAbility } from '@casl/react';
import { CButton, CPopover, CSpinner } from '@coreui/react';
import { PolicyAction, PolicySubject } from '@nccore/shared';
import { each, isArray, isEmpty } from 'lodash';
import { AbilityContext } from '../context/ability.context';
import { useState } from 'react';
import CIcon from '@coreui/icons-react';

interface AppButtonProps {
  onClick: (e: any) => void;
  title?: string;
  icon?: string[];
  size?: 'sm' | 'lg';
  className?: string;
  color?: string;
  disabled?: boolean;
  hint?: string;
  isLoading?: boolean;
  policyAction?: PolicyAction | PolicyAction[];
  policySubject?: PolicySubject | PolicySubject[];
  type?: 'create' | 'save' | 'delete';
}

const buttonTypePolicyActions = {
  create: [PolicyAction.Create, PolicyAction.Manage],
  save: [PolicyAction.Update, PolicyAction.Manage],
  delete: [PolicyAction.Delete, PolicyAction.Manage],
};

const AppButton = (props: AppButtonProps) => {
  const { onClick, title, icon, size, className, color, disabled, policyAction, policySubject, isLoading, type, hint } = props;
  const userAbility = useAbility(AbilityContext);
  const [popoverVisible, setPopoverVisible] = useState(false);

  let action = policyAction;
  if (!isEmpty(type) && isEmpty(action)) {
    action = buttonTypePolicyActions[type];
  }

  let can = true;
  if (isArray(policySubject)) {
    each(policySubject, (subject) => {
      if (isArray(action)) {
        each(action, (a) => {
          if (!userAbility.can(a, subject)) {
            can = false;
          }
        });
      } else {
        if (!userAbility.can(action, subject)) {
          can = false;
        }
      }
    });
  } else {
    if (isArray(action)) {
      each(action, (a) => {
        if (!userAbility.can(a, policySubject)) {
          can = false;
        }
      });
    } else {
      if (action) {
        can = userAbility.can(action, policySubject);
      }
    }
  }

  if (!can) {
    return <></>;
  }

  if (!isEmpty(hint)) {
    return (
      <CPopover content={hint ?? ''} placement="left" visible={popoverVisible}>
        <CButton
          disabled={disabled || isLoading}
          color={color ? color : 'warning'}
          size={size ? size : null}
          className={`${className} pe-auto`}
          onPointerOver={(e) => setPopoverVisible(true)}
          onPointerLeave={(e) => setPopoverVisible(false)}
        >
          {isLoading && (
            <>
              <CSpinner size="sm" as="span" aria-hidden="true" />
              &nbsp;
            </>
          )}
          {icon && <CIcon icon={icon} className={!isEmpty(title) ? 'mr-1' : ''} />}
          {title ?? ''}
        </CButton>
      </CPopover>
    );
  }

  return (
    <CButton disabled={disabled || isLoading} color={color ? color : 'warning'} size={size ? size : null} className={className} onClick={(e) => onClick(e)}>
      {isLoading && (
        <>
          <CSpinner size="sm" as="span" aria-hidden="true" />
          &nbsp;
        </>
      )}
      {icon && <CIcon icon={icon} className={!isEmpty(title) ? 'mr-1' : ''} />}
      {title ?? ''}
    </CButton>
  );
};
export default AppButton;
