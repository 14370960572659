import { Expose, Type } from 'class-transformer';
import { InputFilterEntity } from '../inputfilter';
import { DocumentTemplateSlotEntity } from './documenttemplateslot.entity';

export class DocumentTemplateFilterEntity {
  @Expose()
  uuid?: string;

  @Expose()
  @Type(() => InputFilterEntity)
  inputFilter: InputFilterEntity;

  @Expose()
  position: number;

  @Expose()
  @Type(() => DocumentTemplateSlotEntity)
  source?: DocumentTemplateSlotEntity;

  @Expose()
  @Type(() => DocumentTemplateSlotEntity)
  destination: DocumentTemplateSlotEntity;
}
