import { ChangelogDto, ChangelogNewsletterDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { ChangelogModel } from '../../models/changelog';

// Define a service using a base URL and expected endpoints
export const changelogApi = createApi({
  reducerPath: 'changelogApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/changelog',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    get: builder.query<ChangelogModel, string>({ query: (uuid) => uuid, keepUnusedDataFor: 0 }),
    update: builder.query<ChangelogModel, { uuid: string; dto: ChangelogDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: uuid, body: dto }),
    }),
    create: builder.query<ChangelogModel, ChangelogDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
    }),
    delete: builder.query<ChangelogModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: uuid }),
    }),
    getLatest: builder.query<ChangelogModel, void>({ query: () => 'latest', keepUnusedDataFor: 0 }),
    sendNewsletter: builder.query<void, ChangelogNewsletterDto>({
      query: (dto) => ({ method: 'POST', url: 'send-newsletter', body: dto }),
    }),
  }),
});

export const { useGetQuery, useLazyUpdateQuery, useLazyCreateQuery, useLazyDeleteQuery, useLazyGetLatestQuery, useLazySendNewsletterQuery } = changelogApi;
