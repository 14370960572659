import { Expose } from 'class-transformer';
import { IsOptional } from 'class-validator';
import { TagContentDto } from './tag.content.dto';

export class TagDto {
  @Expose()
  @IsOptional()
  uuid?: string;
  @Expose()
  title: string;
  @Expose()
  content: TagContentDto[];
}
