import { Expose } from 'class-transformer';
import { IsOptional } from 'class-validator';

export class InputFilterDto {
  @Expose()
  @IsOptional()
  uuid?: string;
  @Expose()
  title: string;
  @Expose()
  className: string;
  @Expose()
  @IsOptional()
  parameters?: string;
}
