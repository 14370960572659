import { SearchOptionsDto } from '@nccore/shared';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { post } from '../../api';
import { isArray } from 'lodash';

export interface FetchTableDataProps {
  url: string;
  searchOptions: SearchOptionsDto;
}

export const fetchData = createAsyncThunk('table/fetch', async (fetchData: FetchTableDataProps) => {
  const result = await post(fetchData.url, fetchData.searchOptions);
  return result.data;
});

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    isLoading: true,
    tableData: [],
    page: 1,
    pageSize: 10,
    count: 0,
    sortField: null,
    sortOrder: null,
    filter: null,
    requestUrl: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state.isLoading = true;
        if (state.requestUrl !== action.meta.arg.url) {
          state.tableData = [];
          state.page = 1;
          state.pageSize = 10;
          state.count = 0;
          state.sortField = null;
          state.sortOrder = null;
          state.filter = [];
        }

        state.page = action.meta.arg.searchOptions.page;
        state.pageSize = action.meta.arg.searchOptions.pageSize;
        state.filter = action.meta.arg.searchOptions.filter;
        state.sortField = action.meta.arg.searchOptions.sortField;
        state.sortOrder = action.meta.arg.searchOptions.sortOrder;
        state.requestUrl = action.meta.arg.url;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.tableData = action.payload.user;
        state.isLoading = false;

        let data = action.payload,
          count = data.length;
        if (!isArray(data)) {
          count = data.count;
          data = data.data;
        }

        state.count = count;
        state.tableData = data;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.isLoading = false;
        console.error(action);
      });
  },
});

export default tableSlice.reducer;
