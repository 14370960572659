import { StatsListDto, StatsChartDto, StatsValueDto, StatsPieDto, StatsBarDto, StatsTimeRangeDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';

// Define a service using a base URL and expected endpoints
export const statsApi = createApi({
  reducerPath: 'statsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/stats',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    getList: builder.query<any, StatsListDto>({
      query: (dto) => ({
        method: 'POST',
        url: 'list',
        body: dto,
      }),
    }),
    getChartViewsClicks: builder.query<any, StatsChartDto>({
      query: (dto) => ({
        method: 'POST',
        url: 'chartViewsClicks',
        body: dto,
      }),
    }),
    getPieChart: builder.query<any, StatsPieDto>({
      query: (dto) => ({
        method: 'POST',
        url: 'pieChart',
        body: dto,
      }),
    }),
    getBarChart: builder.query<any, StatsBarDto>({
      query: (dto) => ({
        method: 'POST',
        url: 'barChart',
        body: dto,
      }),
    }),
    getTimeRangeChart: builder.query<any, StatsTimeRangeDto>({
      query: (dto) => ({
        method: 'POST',
        url: 'timeRangeChart',
        body: dto,
      }),
    }),
    getValue: builder.query<any, StatsValueDto>({
      query: (dto) => ({
        method: 'POST',
        url: 'value',
        body: dto,
      }),
    }),
  }),
});

export const {
  useGetListQuery,
  useGetChartViewsClicksQuery,
  useLazyGetChartViewsClicksQuery,
  useGetValueQuery,
  useGetPieChartQuery,
  useLazyGetPieChartQuery,
  useGetBarChartQuery,
  useLazyGetBarChartQuery,
  useGetTimeRangeChartQuery,
  useLazyGetTimeRangeChartQuery,
} = statsApi;
