import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { post } from '../../api';

export const getOrganisations = createAsyncThunk(
  'organisation/getList',
  async () => {
    const result = await post('/organisation/list');
    return result.data;
  }
);

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState: {
    organisations: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganisations.fulfilled, (state, action) => {
        state.organisations = action.payload.data;
      })
      .addCase(getOrganisations.rejected, (state, action) => {
        state.organisations = [];
      });
  },
});

export default organisationSlice.reducer;
