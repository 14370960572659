import { RequestPasswordResetDto, LoginUserDto, ChangeContextOrgaDto, ResetPasswordDto, RegisterUserDto, ActivateUserDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isEmpty } from 'lodash';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { CredentialsModel, OrganisationModel, UserModel } from '../../models';
import { logout, setContextOrga, setToken, setUser } from '../slices/credentialsSlice';

// Define a service using a base URL and expected endpoints
export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/auth',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    register: builder.query<UserModel, RegisterUserDto>({
      query: (dto) => ({ method: 'POST', url: 'register', body: dto }),
    }),
    login: builder.query<CredentialsModel, LoginUserDto>({
      query: (dto) => ({ method: 'POST', url: 'login', body: dto }),
      keepUnusedDataFor: 0,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data.user));
          dispatch(setToken(data.token));
        } catch (error) {
          // dispatch(logout());
          console.error(error);
        }
      },
    }),
    profile: builder.query<CredentialsModel, void>({
      query: () => ({ method: 'GET', url: 'profile' }),
      keepUnusedDataFor: 0,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data.user));
          dispatch(setToken(data.token));
        } catch (error) {
          console.error('profile ERROR:', error);
          if (!isEmpty(error.error) && error.error.status == 400) {
            dispatch(logout());
          }
          //alert('ERROR: ' + JSON.stringify(error, null, 2));
          // TODO: wenn der FETCH abgebrochen wird, zB durch reload, kommt ebenfalls ein Fehler
          //dispatch(logout());
        }
      },
    }),
    requestPasswordReset: builder.query<UserModel, RequestPasswordResetDto>({
      query: (dto) => ({
        method: 'POST',
        url: 'request-password-reset',
        body: dto,
      }),
    }),
    getUserByResetToken: builder.query<UserModel, string>({
      query: (token) => ({ method: 'GET', url: 'resetToken/' + token }),
    }),
    resetPassword: builder.query<UserModel, { dto: ResetPasswordDto }>({
      query: ({ dto }) => ({ method: 'POST', url: 'resetPassword', body: dto }),
    }),
    activateUser: builder.query<UserModel, ActivateUserDto>({
      query: (dto) => ({ method: 'POST', url: 'activate', body: dto }),
    }),
    deactivateNewsletter: builder.query<void, string>({
      query: (uuid) => ({ method: 'GET', url: `deactivate-newsletter/${uuid}` }),
    }),
    getEnabledOrganisations: builder.query<OrganisationModel[], void>({
      query: () => ({ method: 'GET', url: 'organisations' }),
    }),
    changeContextOrga: builder.query<OrganisationModel, ChangeContextOrgaDto>({
      query: (dto) => ({ method: 'POST', url: 'changeContextOrga', body: dto }),
      keepUnusedDataFor: 0,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!isEmpty(data)) {
            dispatch(setContextOrga(data));
          }
        } catch (error) {
          console.error('changeContextOrga ERROR:', error);
          //alert('ERROR: ' + JSON.stringify(error, null, 2));
          // TODO: wenn der FETCH abgebrochen wird, zB durch reload, kommt ebenfalls ein Fehler
          //dispatch(logout());
        }
      },
    }),
  }),
});

export const {
  useLazyLoginQuery,
  useProfileQuery,
  useLazyProfileQuery,
  useLazyRequestPasswordResetQuery,
  useLazyChangeContextOrgaQuery,
  useGetUserByResetTokenQuery,
  useLazyResetPasswordQuery,
  useGetEnabledOrganisationsQuery,
  useLazyRegisterQuery,
  useLazyActivateUserQuery,
  useLazyDeactivateNewsletterQuery,
} = authApi;
