import { BaseSyntheticEvent, ReactElement, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { clone, isEmpty, isNull, reverse, sortBy, filter as filterArr, isArray, isFunction, ceil, isNil } from 'lodash';
import { useLazyFetchListQuery } from '../store/apis/tableApi';
import { SearchOptionsDto } from '@nccore/shared';
import { CButton, CCol, CContainer, CForm, CFormInput, CFormSelect, CFormSwitch, CInputGroup, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilArrowBottom, cilArrowTop, cilPlus, cilTrash, cilXCircle } from '@coreui/icons';
import AppButton from './AppButton';
import { download } from '../api';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  ColumnDef,
  PaginationState,
  RowData,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

interface FilterComponentProps {
  onFilter: (e) => void;
  onClear: () => void;
  filter: string;
}

const FilterComponent = (props: FilterComponentProps) => {
  const { onFilter, onClear, filter } = props;

  return (
    <CForm className="filter-component mb-1">
      <CInputGroup>
        <CFormInput
          type="text"
          value={filter}
          onChange={onFilter}
          placeholder="Filter"
          onSubmit={(e) => e.preventDefault()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              return;
            }
          }}
        />
        <CButton color="secondary" onClick={onClear}>
          <CIcon icon={cilXCircle} />
        </CButton>
      </CInputGroup>
    </CForm>
  );
};

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

const editableColumn: Partial<ColumnDef<any>> = {
  cell: ({ row, column: { id, columnDef }, table }) => {
    const initialValue = row.original[id];
    const [value, setValue] = useState(initialValue ?? '');

    const onBlur = () => {
      table.options.meta?.updateData(row.index, id, value);
    };

    useEffect(() => {
      setValue(initialValue ?? '');
    }, [initialValue]);

    switch ((columnDef.meta as any)?.dataType) {
      case 'boolean':
        return <CFormSwitch checked={value} onChange={(e) => setValue(e.target.checked)} onBlur={onBlur} />;
      default:
        return <CFormInput value={value as string} onChange={(e) => setValue(e.target.value)} onBlur={onBlur} size="sm" />;
    }
  },
};

export interface AppTableHandler {
  reloadData: () => void;
}

interface AppTableOptions {
  serverSide?: boolean;
  filterFields?: string[];
  allowEdit?: boolean;
  sumFooter?: boolean;
}

interface AppTableProps {
  requestUrl?: string;
  data?: any;
  columns: ColumnDef<any>[];
  options?: AppTableOptions;
  onRowClick?: (row: any, event: any) => void;
  additionalRequestData?: any;
  defaultSortField?: string;
  defaultSortDirection?: 'asc' | 'desc';
  withExport?: boolean;
  onChange?: (data: any) => void;
  footerAdditional?: ReactElement;
}

const AppTable = forwardRef<AppTableHandler, AppTableProps>((props: AppTableProps, ref) => {
  const {
    footerAdditional,
    requestUrl,
    data,
    columns,
    options,
    onRowClick,
    additionalRequestData,
    defaultSortDirection,
    defaultSortField,
    withExport,
    onChange,
  } = props;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
  const [filterTimeout, setFilterTimeout] = useState(null);
  const [filter, setFilter] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [currentDto, setCurrentDto] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const { t } = useTranslation();

  const [triggerFetchPage, { data: pageData, isLoading, isFetching }] = useLazyFetchListQuery();

  useImperativeHandle(ref, () => ({
    reloadData() {
      loadData();
    },
  }));

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (isEmpty(data) || isEmpty(defaultSortDirection) || isEmpty(defaultSortField)) {
      return;
    }

    let sorted = sortBy(data, (d) => d[defaultSortField]);
    if (defaultSortDirection == 'desc') {
      sorted = reverse(sorted);
    }

    setSortedData(sorted);
  }, [data]);

  useEffect(() => {
    loadData();
  }, [additionalRequestData]);

  useEffect(() => {
    if (options?.serverSide) {
      loadData();
    }
  }, [sorting, pagination]);

  const getSortField = () => {
    if (isEmpty(sorting)) {
      return null;
    }
    return sorting[0].id;
  };

  const getSortOrder = () => {
    if (isEmpty(sorting)) {
      return null;
    }
    return sorting[0].desc ? 'desc' : 'asc';
  };

  const getPage = () => {
    if (options?.serverSide) return pagination.pageIndex + 1;
    return null;
  };

  const getPageSize = () => {
    if (options?.serverSide) return pagination.pageSize;
    return null;
  };

  const getPageCount = () => {
    let pageCount = 0;

    if (!isEmpty(pageData)) {
      pageCount = pageData?.count / pagination.pageSize;
    }
    if (!isEmpty(data)) {
      pageCount = data.length / pagination.pageSize;
    }

    return ceil(pageCount);
  };

  const getTableData = () => {
    let tableData: any = pageData?.data;
    if (!isEmpty(data)) {
      tableData = data;
    }
    if (!isEmpty(sortedData)) {
      tableData = sortedData;
    }

    return tableData;
  };

  const addRow = () => {
    const tmp = clone(data);
    tmp.push({});

    onChange(tmp);
  };

  const removeRow = (index) => {
    const tmp = filterArr(data, (row, i) => {
      return i !== index;
    });

    onChange(tmp);
  };

  const moveRow = (index, newIndex) => {
    if (newIndex < 0 || newIndex >= data?.length || !isArray(data)) {
      return;
    }

    const tmp = clone(data);
    tmp.splice(newIndex, 0, tmp.splice(index, 1)[0]);
    onChange(tmp);
  };

  const getColumns = () => {
    if (!options?.allowEdit) return columns;

    const columnsClone = clone(columns);

    columnsClone.push({
      id: 'actions',
      header: () => (
        <CButton variant="outline" onClick={() => addRow()} size="sm" color="primary">
          <CIcon icon={cilPlus} />
        </CButton>
      ),
      cell: ({ row }) => (
        <>
          <CButton
            variant="outline"
            color="primary"
            size="sm"
            className="ml-1"
            onClick={() => {
              moveRow(row.index, row.index - 1);
            }}
          >
            <CIcon icon={cilArrowTop} />
          </CButton>
          <CButton
            variant="outline"
            color="primary"
            size="sm"
            className="ml-1"
            onClick={() => {
              moveRow(row.index, row.index + 1);
            }}
          >
            <CIcon icon={cilArrowBottom} />
          </CButton>
          <CButton
            variant="outline"
            size="sm"
            color="danger"
            className="ml-1"
            onClick={() => {
              removeRow(row.index);
            }}
          >
            <CIcon icon={cilTrash} />
          </CButton>
        </>
      ),
    });

    return columnsClone;
  };

  const loadData = (newRequestOptions?: { page?: number; pageSize?: number; sortField?: string; sortOrder?: string; filterText?: string }) => {
    if (!isEmpty(data) || isEmpty(requestUrl)) {
      return;
    }

    if (!isEmpty(additionalRequestData)) {
      const dto: SearchOptionsDto = {
        ...additionalRequestData,
        page: newRequestOptions?.page ?? getPage(),
        pageSize: newRequestOptions?.pageSize ?? getPageSize(),
        sortField: newRequestOptions?.sortField ?? getSortField(),
        sortOrder: newRequestOptions?.sortOrder ?? getSortOrder(),
        filterText: newRequestOptions?.filterText ?? filter,
        filterFields: options?.filterFields ?? [],
      };

      if (isNull(dto.sortField) && !isEmpty(defaultSortField)) {
        dto.sortField = defaultSortField;
        dto.sortOrder = defaultSortDirection;
      }

      triggerFetchPage({ url: requestUrl, dto });
      setCurrentDto(dto);
      return;
    }

    const dto: SearchOptionsDto = {
      page: newRequestOptions?.page ?? getPage(),
      pageSize: newRequestOptions?.pageSize ?? getPageSize(),
      sortField: newRequestOptions?.sortField ?? getSortField(),
      sortOrder: newRequestOptions?.sortOrder ?? getSortOrder(),
      filterText: newRequestOptions?.filterText ?? filter,
      filterFields: options?.filterFields ?? [],
    };

    if (isNull(dto.sortField) && !isEmpty(defaultSortField)) {
      dto.sortField = defaultSortField;
      dto.sortOrder = defaultSortDirection;
    }

    triggerFetchPage({ url: requestUrl, dto });
    setCurrentDto(dto);
  };

  const onFilterChange = (filter: string) => {
    setFilter(filter);

    clearTimeout(filterTimeout);
    setFilterTimeout(null);

    setFilterTimeout(
      setTimeout(() => {
        setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
        setResetPaginationToggle(!resetPaginationToggle);
        loadData({ filterText: filter, page: 0, pageSize: pagination.pageSize });
        setFilterTimeout(null);
      }, 1000),
    );
  };

  const handleFilterClear = () => {
    if (filter) {
      onFilterChange('');
    }
  };

  const exportXLS = async () => {
    setDownloading(true);
    await download(
      '/stats/export',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'stats_' + moment().format('YYYY_MM_DD_HH_mm'),
      'xlsx',
      {
        ...currentDto,
        enrich: { fields: ['hub', 'document', 'stream'] },
      },
    );
    setDownloading(false);
  };

  const table = useReactTable({
    data: getTableData() ?? [],
    pageCount: getPageCount() ?? -1,
    columns: getColumns() ?? [],
    state: {
      sorting,
      pagination,
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        onChange(
          data.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...data[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          }),
        );
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualSorting: options?.serverSide,
    manualPagination: options?.serverSide || options?.allowEdit,
    defaultColumn: options?.allowEdit ? editableColumn : null,
  });

  return (
    <>
      {withExport ? (
        <div style={{ float: 'right' }}>
          <AppButton title={t('export')} onClick={() => exportXLS()} isLoading={downloading} />
        </div>
      ) : (
        <></>
      )}

      <div className="datatable-container p-2">
        {!isEmpty(options?.filterFields) ? (
          <FilterComponent onFilter={(e) => onFilterChange(e.target.value)} onClear={handleFilterClear} filter={filter} />
        ) : (
          <></>
        )}
        <table className={`datatable ${isFetching || isLoading ? 'loading' : ''}`}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            style: { cursor: header.column.getCanSort() ? 'pointer' : 'inherit' },
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <CIcon icon={cilArrowTop} className="ml-1" />,
                            desc: <CIcon icon={cilArrowBottom} className="ml-1" />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className={isFunction(onRowClick) ? 'hover' : ''}
                  onClick={(e) => {
                    if (isFunction(onRowClick) && (e as BaseSyntheticEvent)?.target?.nodeName !== 'BUTTON') {
                      onRowClick(row.original, e);
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} style={{ cursor: isFunction(onRowClick) ? 'pointer' : 'inherit' }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {!isFetching && !isLoading && isEmpty(getTableData()) ? (
          <div style={{ textAlign: 'center' }} className="p-2">
            {t('no-data-found')}
          </div>
        ) : (
          <></>
        )}
        {!options?.allowEdit && !isEmpty(getTableData()) ? (
          <CContainer className="mt-2" fluid>
            <CRow className="align-items-center">
              <CCol style={{ textAlign: 'left' }} xs={2}>
                <span>
                  {t('page')} {table.getState().pagination.pageIndex + 1} {t('of')} {table.getPageCount()}
                </span>
              </CCol>
              <CCol style={{ textAlign: 'right' }}>
                <span className="ml-2">
                  {t('rows-per-page')}
                  <CFormSelect
                    size="sm"
                    className="size-selector ml-1"
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </CFormSelect>
                </span>
                <span className="ml-2 hidden-m">
                  {t('go-to-page')}
                  <CFormInput
                    size="sm"
                    type="number"
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0;
                      table.setPageIndex(page);
                    }}
                    className="page-input ml-1"
                  />
                </span>
                <span className="ml-2" style={{ display: 'inline-flex' }}>
                  <CButton onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage() || isFetching} color="secondary" size="sm">
                    {'<<'}
                  </CButton>
                  <CButton
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage() || isFetching}
                    color="secondary"
                    size="sm"
                    className="ml-1"
                  >
                    {'<'}
                  </CButton>
                  <CButton onClick={() => table.nextPage()} disabled={!table.getCanNextPage() || isFetching} color="secondary" size="sm" className="ml-1">
                    {'>'}
                  </CButton>
                  <CButton
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage() || isFetching}
                    color="secondary"
                    size="sm"
                    className="ml-1"
                  >
                    {'>>'}
                  </CButton>
                </span>
              </CCol>
            </CRow>
          </CContainer>
        ) : (
          <></>
        )}
        {!isNull(footerAdditional) ? <CContainer fluid>{footerAdditional}</CContainer> : <></>}
      </div>
    </>
  );
});

export default AppTable;
