import { HubDto, StreamDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { HubModel, StreamModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const hubApi = createApi({
  reducerPath: 'hubApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/hub',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    getHub: builder.query<HubModel, string>({ query: (uuid) => uuid, keepUnusedDataFor: 0 }),
    updateHub: builder.query<HubModel, { uuid: string; dto: HubDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: uuid, body: dto }),
    }),
    createHub: builder.query<HubModel, HubDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
    }),
    deleteHub: builder.query<HubModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: uuid }),
    }),
    createStream: builder.query<StreamModel, StreamDto>({
      query: (dto) => ({ method: 'POST', url: '/stream/', body: dto }),
    }),
    updateStream: builder.query<StreamModel, { uuid: string; dto: StreamDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: '/stream/' + uuid, body: dto }),
    }),
    deleteStream: builder.query<StreamModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: '/stream/' + uuid }),
    }),
  }),
});

export const {
  useGetHubQuery,
  useLazyUpdateHubQuery,
  useLazyCreateHubQuery,
  useLazyDeleteHubQuery,
  useLazyCreateStreamQuery,
  useLazyUpdateStreamQuery,
  useLazyDeleteStreamQuery,
} = hubApi;
