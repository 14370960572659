import { Exclude, Expose, Type } from 'class-transformer';
import { MediaEntity, ExternalMediaPublishedEntity } from '../media';
import type { MediaEntity as MediaEntityType, ExternalMediaPublishedEntity as ExternalMediaPublishedEntityType } from '../media';
import { StreamEntity } from '../stream';
import { TrackingCodeEntity } from '../trackingcode';
import { HubEntity } from '../hub';

export class OrganisationEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  description?: string;

  @Expose()
  color?: string;

  @Expose()
  homepage?: string;

  @Expose()
  @Type(() => MediaEntity)
  logo: MediaEntityType;

  @Expose()
  @Type(() => StreamEntity)
  enabledStreams?: StreamEntity[];

  @Expose()
  @Type(() => OrganisationEntity)
  childOrganisations?: OrganisationEntity[];

  @Expose()
  @Type(() => HubEntity)
  hubs?: HubEntity[];

  @Expose()
  @Type(() => TrackingCodeEntity)
  trackingCodes?: TrackingCodeEntity[];

  @Expose()
  createdAt?: Date;

  @Expose()
  updatedAt?: Date;

  @Expose()
  deletedAt?: Date;
}

export class ExternalOrganisationEntity extends OrganisationEntity {
  @Exclude()
  trackingCodes?: TrackingCodeEntity[];

  @Exclude()
  childOrganisations?: OrganisationEntity[];

  @Exclude()
  enabledStreams?: StreamEntity[];

  @Exclude()
  isSuperOrga?: boolean;

  @Exclude()
  updatedAt?: Date;

  @Expose()
  @Type(() => ExternalMediaPublishedEntity)
  logo: ExternalMediaPublishedEntityType;
}
