import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    sidebar: true,
    unfoldable: false,
    isLoading: false,
    toast: null,
  },
  reducers: {
    setVisible: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.sidebar = action.payload.visible;
    },
    toggle: (state) => {
      state.sidebar = !state.sidebar;
    },
    toggleUnfoldable: (state) => {
      state.unfoldable = !state.unfoldable;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    addToast: (state, action: PayloadAction<{ message: string; title?: string; color: string }>) => {
      state.toast = action.payload;
    },
  },
});

export const { toggle, toggleUnfoldable, setVisible, setLoading, addToast } = uiSlice.actions;

export default uiSlice.reducer;
