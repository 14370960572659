import { Fragment, forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { CForm, CRow, CCol, CFormLabel, CFormTextarea, CFormInput, CFormSwitch, CInputGroup, CButton, CFormCheck, CFormText } from '@coreui/react';
import MediaSelectComponent from './media/MediaSelectComponent';
import CIcon from '@coreui/icons-react';
import { cilClipboard, cilPlus, cilTrash } from '@coreui/icons';
import copy from 'copy-to-clipboard';
import AlertContext from './AppAlertProvider';
import AppSelect from './AppSelect';
import { AppDateTimeSelector, AppEditor } from '.';
import { cloneDeep, find, findIndex, indexOf, isArray, isEmpty, isFunction, isNil, isString, map, remove } from 'lodash';
import { get as getProperty, set as setProperty } from 'dot-prop';
import EditorTagComponent from './editor/EditorTagComponent';
import AppButton from './AppButton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);
export type PossibleFormWidgets =
  | 'input'
  | 'datepicker'
  | 'textarea'
  | 'editor'
  | 'code'
  | 'color'
  | 'switch'
  | 'radio'
  | 'selector'
  | 'mediaselector'
  | 'copytoclipboard-ro'
  | 'number'
  | 'tag'
  | 'form'
  | 'array';

interface QuickFormFieldOptions {
  selectOptions?: Array<any> | Array<{ value: string; title: string }>;
  radioOptions?: any;
  returnOptionValue?: boolean;
  isMultiSelect?: boolean;
  isLoading?: boolean;
  valueFormatter?: (value: any) => any;
  returnValueFormatter?: (value: any) => any;
  selectValueFormatter?: (option: any) => string;
  selectTitleFormatter?: (option: any) => string;
  selectImageSelector?: (options: any) => string;
  cardLayout?: boolean;
  inputType?: 'text' | 'password' | 'email' | 'number';
  clearButton?: boolean;
  hidden?: boolean;
  required?: boolean;
  arrayKey?: string | number;
  arrayPredicate?: (val: any) => boolean;
  additionalData?: any;
  sourceExtension?: 'html' | 'css' | 'javascript' | 'json';
  formFields?: QuickFormFields;
  arrayWidget?: PossibleFormWidgets;
  disableStickyToolbar?: boolean;
  dateFormat?: string;
}

export interface QuickFormField {
  name: string;
  label?: string;
  widget?: PossibleFormWidgets;
  title?: string;
  text?: string;
  options?: QuickFormFieldOptions;
  valid?: boolean;
  value?: any;
}

export type QuickFormFields = Array<QuickFormField>;

interface QuickFormProps {
  obj: any;
  name: string;
  fields: QuickFormFields;
  onChange: (obj: any, isValid: boolean, changedField: QuickFormField) => void;
  isEmbeddedForm?: boolean;
  colLeft?: number;
  colRight?: number;
  editableFields?: boolean;
  onChangeFields?: (fields: QuickFormFields) => void;
}

export interface QuickFormHandler {
  isValid: () => boolean;
}

const QuickForm = forwardRef<QuickFormHandler, QuickFormProps>((props: QuickFormProps, ref) => {
  let { colRight = 10 } = props;
  const { obj, name, fields, onChange, isEmbeddedForm = false, colLeft = 2, editableFields, onChangeFields } = props;
  const alert = useContext(AlertContext);
  const { t } = useTranslation();

  const [changedFields, setChangedFields] = useState<number[]>([]);

  if (colLeft + colRight > 12) {
    colRight = 12 - colLeft;
  }

  useImperativeHandle(ref, () => ({
    isValid() {
      return isFormValid();
    },
  }));

  const copytoclipboard = (value) => {
    const success = copy(value);
    if (success) {
      alert.showSuccess('Wert kopiert');
    } else {
      alert.showInfo('Das kopieren in die Zwischenablage hat eventuell nicht funktioniert');
    }
  };

  const internalOnChange = (field: QuickFormField, fieldKey: number, newValue) => {
    const newObject = setFieldValue(field, newValue);

    if (indexOf(changedFields, fieldKey) === -1) {
      setChangedFields([...changedFields, fieldKey]);
    }

    onChange(newObject, isFormValid(newObject), field);
  };

  const isFieldValid = (field: QuickFormField, fieldKey: number) => {
    if (!field.options?.required) {
      return null;
    }

    if (indexOf(changedFields, fieldKey) === -1 && isEmpty(getFieldValue(field))) {
      return null;
    }

    return isEmpty(getFieldValue(field)) ? false : true;
  };

  const isFieldInvalid = (field: QuickFormField, fieldKey: number) => {
    if (!field.options?.required) {
      return null;
    }

    if (indexOf(changedFields, fieldKey) === -1) {
      return null;
    }

    return isEmpty(getFieldValue(field)) ? true : false;
  };

  const isFormValid = (newObject?: any) => {
    let isValid = true;
    fields.forEach((field) => {
      if (field.options?.required) {
        if (!isEmpty(newObject)) {
          if (isEmpty(getFieldValue(field, newObject))) {
            isValid = false;
          }
        } else {
          if (isEmpty(getFieldValue(field))) {
            isValid = false;
          }
        }
      }
    });
    return isValid;
  };

  const formatValue = (value: any, field: QuickFormField) => {
    if (isFunction(field.options?.valueFormatter)) {
      return field.options?.valueFormatter(value);
    }

    return value;
  };

  const getFieldValue = (field: QuickFormField, useObj?: any) => {
    if (isEmpty(useObj)) {
      useObj = cloneDeep(obj);
    }

    if (!isNil(field.options?.arrayKey)) {
      const array = getProperty(useObj, field.name) ?? [];
      return formatValue(array[field.options?.arrayKey], field);
    }

    if (!isNil(field.options?.arrayPredicate)) {
      const array: any = getProperty(useObj, field.name) ?? [];
      return formatValue(find(array, field.options.arrayPredicate), field);
    }

    if (isEmpty(field.name) || isEmpty(useObj)) {
      return formatValue('', field);
    }

    return formatValue(getProperty(useObj, field.name) as any, field);
  };

  const setFieldValue = (field: QuickFormField, value: any, useObj?: any) => {
    if (isEmpty(useObj)) {
      useObj = cloneDeep(obj);
    }
    if (isEmpty(useObj)) {
      useObj = {};
    }

    if (isFunction(field.options?.returnValueFormatter)) {
      value = field.options?.returnValueFormatter(value);
    }

    if (!isNil(field.options?.arrayKey)) {
      let array = getProperty(useObj, field.name) ?? [];
      array[field.options?.arrayKey] = value;
      value = array;
    }

    if (!isNil(field.options?.arrayPredicate)) {
      const array: any = getProperty(useObj, field.name) ?? [];
      const index = findIndex(array, field.options.arrayPredicate);
      if (index === -1) {
        array.push(value);
      } else {
        array[index] = value;
      }
      value = array;
    }

    setProperty(useObj, field.name, value);
    return useObj;
  };

  const renderWidgetInput = (field: QuickFormField, key: number) => {
    switch (field['widget']) {
      case 'textarea':
        return (
          <CFormTextarea
            id={name + '_' + field.name}
            name={field.name}
            value={getFieldValue(field)}
            onChange={(e) => internalOnChange(field, key, e.target.value)}
            title={field.title}
            required={field.options?.required}
            valid={isFieldValid(field, key)}
            invalid={isFieldInvalid(field, key)}
          />
        );
      case 'color':
        return (
          <CFormInput
            id={name + '_' + field.name}
            name={field.name}
            type="color"
            value={getFieldValue(field)}
            onChange={(e) => internalOnChange(field, key, e.target.value)}
            title={field.title}
            text={field.text}
            required={field.options?.required}
            valid={isFieldValid(field, key)}
            invalid={isFieldInvalid(field, key)}
          />
        );
      case 'switch':
        return (
          <CFormSwitch
            id={name + '_' + field.name}
            name={field.name}
            type="checkbox"
            label={field.label}
            checked={getFieldValue(field)}
            onChange={(e) => internalOnChange(field, key, e.target.checked)}
            title={field.title}
            required={field.options?.required}
            valid={isFieldValid(field, key)}
            invalid={isFieldInvalid(field, key)}
          />
        );
      case 'radio':
        return (
          <CRow>
            {map(field.options?.radioOptions, (option, radioKey) => {
              return (
                <CFormCheck
                  type="radio"
                  id={name + '_' + field.name + '_' + radioKey}
                  name={field.name}
                  label={option.label}
                  value={option.value}
                  key={radioKey}
                  checked={getFieldValue(field) == option.value}
                  onChange={(e) => internalOnChange(field, key, option.value)}
                  title={field.title}
                  required={field.options?.required}
                  valid={isFieldValid(field, key)}
                  invalid={isFieldInvalid(field, key)}
                />
              );
            })}
          </CRow>
        );
      case 'selector':
        return (
          <AppSelect
            options={field.options?.selectOptions}
            isMulti={field.options?.isMultiSelect}
            isLoading={field.options?.isLoading}
            value={getFieldValue(field)}
            onChange={(e) => internalOnChange(field, key, e)} /* cannot set to uuid because we need the whole entity (see UserComponent) */
            titleFormatter={field.options?.selectTitleFormatter}
            valueFormatter={field.options?.selectValueFormatter}
            imageSelector={field.options?.selectImageSelector}
            cardLayout={field.options?.cardLayout}
            returnOptionValue={field.options?.returnOptionValue}
          />
        );
      case 'mediaselector':
        return (
          <MediaSelectComponent
            /*id={name + "_" + field.name} name={field.name}*/ value={getFieldValue(field)}
            onChange={(e) => internalOnChange(field, key, e)}
          />
        );
      case 'copytoclipboard-ro':
        const val = field.value ?? getFieldValue(field);

        return (
          <CInputGroup className="p-0">
            <CFormInput aria-label={field.label} value={val} title={field.title} readOnly={true} />
            <CButton type="button" color="secondary" variant="outline" onClick={() => copytoclipboard(val)}>
              <CIcon icon={cilClipboard} />
            </CButton>
          </CInputGroup>
        );
      case 'editor':
        return (
          <AppEditor
            value={getFieldValue(field)}
            onChange={(val) => internalOnChange(field, key, val)}
            disableStickyToolbar={field.options?.disableStickyToolbar}
          />
        );
      case 'code':
        return (
          <AppEditor
            value={isString(getFieldValue(field)) ? getFieldValue(field) : ''}
            onChange={(val) => internalOnChange(field, key, val)}
            sourceOnly={true}
            sourceExtension={field.options?.sourceExtension}
          />
        );
      case 'number':
        return (
          <CFormInput
            id={`${name}_${field.name}`}
            name={field.name}
            type={'number'}
            value={getFieldValue(field)}
            onChange={(e) => internalOnChange(field, key, e.target.value)}
            title={field.title}
            text={field.text}
            required={field.options?.required}
            valid={isFieldValid(field, key)}
            invalid={isFieldInvalid(field, key)}
          />
        );
      case 'datepicker':
        return <AppDateTimeSelector onChange={(val, date) => internalOnChange(field, key, date)} value={getFieldValue(field)} />;
      case 'tag':
        return (
          <EditorTagComponent
            value={getFieldValue(field) ?? ''}
            onChange={(value: string) => internalOnChange(field, key, value)}
            required={field.options?.required}
            invalid={isFieldInvalid(field, key)}
          />
        );
      case 'form':
        if (isEmpty(field.options?.formFields)) {
          return <>Keine Daten ...</>;
        }

        return (
          <QuickForm
            obj={getFieldValue(field) ?? {}}
            name={field.name}
            fields={field.options?.formFields}
            onChange={(val) => internalOnChange(field, key, val)}
            isEmbeddedForm={true}
          />
        );
      case 'array':
        return (
          <>
            {map(getFieldValue(field) ?? [], (arrayItem, arrayKey: number) => {
              return (
                <CRow>
                  <CCol sm={11}>
                    <QuickForm
                      fields={[
                        {
                          label: '',
                          name: `${field.name}_${arrayKey}`,
                          widget: field.options?.arrayWidget,
                          options: { formFields: field.options?.formFields },
                        },
                      ]}
                      obj={{ [`${field.name}_${arrayKey}`]: arrayItem }}
                      name={`${field.name}_${arrayKey}`}
                      onChange={(val) => {
                        let array = getFieldValue(field);
                        if (!isArray(array)) {
                          array = [];
                        }

                        array[arrayKey] = val[`${field.name}_${arrayKey}`];
                        internalOnChange(field, key, array);
                      }}
                      colLeft={0}
                      colRight={12}
                    />
                  </CCol>
                  <CCol>
                    <CButton
                      onClick={() => {
                        let array = getFieldValue(field);
                        if (!isArray(array)) {
                          array = [];
                        }

                        remove(array, (val, index) => index === arrayKey);
                        internalOnChange(field, key, array);
                      }}
                      color="danger"
                    >
                      <CIcon icon={cilTrash} />
                    </CButton>
                  </CCol>
                  <hr />
                </CRow>
              );
            })}
            <CButton
              color="primary"
              className="float-right"
              size="sm"
              onClick={() => {
                let array = getFieldValue(field);
                if (!isArray(array)) {
                  array = [];
                }

                switch (field.options?.arrayWidget) {
                  case 'form':
                    array.push({});
                    break;
                  case 'array':
                    array.push([]);
                    break;
                  default:
                    array.push('');
                    break;
                }

                internalOnChange(field, key, array);
              }}
            >
              <CIcon icon={cilPlus} />
            </CButton>
          </>
        );
      case 'input':
      default:
        return (
          <>
            <CInputGroup>
              <CFormInput
                id={`${name}_${field.name}`}
                name={field.name}
                type={field.options?.inputType ?? 'text'}
                value={getFieldValue(field)}
                onChange={(e) => internalOnChange(field, key, e.target.value)}
                title={field.title}
                valid={isFieldValid(field, key)}
                invalid={isFieldInvalid(field, key)}
                required={field.options?.required}
              />
              {field.options?.clearButton && (
                <CButton color="secondary" onClick={() => internalOnChange(field, key, '')}>
                  <CIcon icon={cilTrash} />
                </CButton>
              )}
            </CInputGroup>
            {field.text && <CFormText>{field.text}</CFormText>}
          </>
        );
    }

    return <></>;
  };

  const renderWidgetLabel = (field: QuickFormField) => {
    if (isEmpty(field.label)) {
      return null;
    }

    switch (field['widget']) {
      case 'switch':
        return null;
      default:
        return (
          <CFormLabel htmlFor={name + '_' + field.name} className="col-form-label">
            {field.label} {field.options?.required && <span style={{ color: 'red' }}>*</span>}
          </CFormLabel>
        );
    }
  };

  return (
    <ConditionalWrapper condition={!isEmbeddedForm} wrapper={(children) => <CForm>{children}</CForm>}>
      <Fragment>
        {fields.map((field, key) => {
          if (field.options?.hidden) {
            return <></>;
          }

          return (
            <CRow className="mb-3" key={key}>
              {colLeft === 0 ? null : <CCol xl={colLeft}>{renderWidgetLabel(field)}</CCol>}
              <CCol xl={colRight}>{renderWidgetInput(field, key)}</CCol>
            </CRow>
          );
        })}
        {editableFields ? (
          <>
            <hr />
            <div style={{ textAlign: 'right' }}>
              <AppButton
                title="Add Field"
                icon={cilPlus}
                color="primary"
                onClick={() => {
                  if (isFunction(onChangeFields)) {
                    onChangeFields(fields);
                  }
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </Fragment>
    </ConditionalWrapper>
  );
});

export default QuickForm;
