import { Expose, Type } from 'class-transformer';
import { DocumentPublishedEntity, ExternalDocumentPublishedEntity } from '../document';

export class StreamContentEntity {
  @Expose()
  uuid: string;

  @Expose()
  title: string;

  @Expose()
  description: string;

  @Expose()
  isActive: boolean;

  @Expose()
  mappingClass: string;

  @Expose()
  @Type(() => DocumentPublishedEntity)
  documents: DocumentPublishedEntity[];
}

export class ExternalStreamContentEntity extends StreamContentEntity {
  @Expose()
  @Type(() => ExternalDocumentPublishedEntity)
  documents: ExternalDocumentPublishedEntity[];
}

export class StreamDocumentEntity {
  @Expose()
  uuid: string;

  @Expose()
  title: string;

  @Expose()
  description: string;

  @Expose()
  isActive: boolean;

  @Expose()
  mappingClass: string;

  @Expose()
  @Type(() => DocumentPublishedEntity)
  document: DocumentPublishedEntity;
}

export class ExternalStreamDocumentEntity extends StreamDocumentEntity {
  @Expose()
  @Type(() => ExternalDocumentPublishedEntity)
  document: ExternalDocumentPublishedEntity;
}
