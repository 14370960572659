import React from 'react';
import { Navigate, Route, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';
import { PrivateRoute } from './components/auth/privateRoute';
import './scss/style.scss';
import CubeLoading from './components/CubeLoading';
import { lazyRetry } from './lazyRetry';
import routes from './routes';
import { isEmpty } from 'lodash';
import { useAbility } from '@casl/react';
import { AbilityContext } from './context/ability.context';

// Containers
const DefaultLayout = React.lazy(() => lazyRetry(() => import('./layout/DefaultLayout')));

// Pages
const Login = React.lazy(() => lazyRetry(() => import('./views/pages/login/Login')));
const RequestResetPassword = React.lazy(() => lazyRetry(() => import('./views/pages/login/RequestResetPassword')));
const ResetPassword = React.lazy(() => lazyRetry(() => import('./views/pages/login/ResetPassword')));
const Page404 = React.lazy(() => lazyRetry(() => import('./views/pages/page404/Page404')));
const Page500 = React.lazy(() => lazyRetry(() => import('./views/pages/page500/Page500')));
const Datenschutz = React.lazy(() => lazyRetry(() => import('./views/pages/Datenschutz')));
const DeactivateNewsletter = React.lazy(() => lazyRetry(() => import('./views/pages/DeactivateNewsletter')));

const Router = () => {
  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: '/datenschutz',
          element: <Datenschutz />,
        },
        { path: '/login', element: <Login /> },
        { path: '/request-reset-password', element: <RequestResetPassword /> },
        {
          path: '/reset-password/:token',
          element: <ResetPassword setPasswordVariant={false} />,
        },
        {
          path: '/set-password/:token',
          element: <ResetPassword setPasswordVariant={true} />,
        },
        {
          path: '/activate/:token',
          element: <Login activateUser={true} />,
        },
        { path: '/deactivate-newsletter/:uuid', Component: DeactivateNewsletter },
        { path: '/404', Component: Page404 },
        { path: '/500', Component: Page500 },
        { path: '*', Component: Root },
      ])}
      fallbackElement={<CubeLoading />}
      future={{ v7_startTransition: true }}
    />
  );
};

const Root = () => {
  const ability = useAbility(AbilityContext);

  return (
    <React.Suspense fallback={<CubeLoading />}>
      <Routes>
        <Route
          element={
            <PrivateRoute>
              <DefaultLayout />
            </PrivateRoute>
          }
        >
          {routes.map((route, idx) => {
            if (!isEmpty(route.ability)) {
              if (!ability.can(route.ability.action, route.ability.subject)) {
                return null;
              }
            }
            return route.component && <Route key={idx} path={route.path} element={<route.component />} />;
          })}
          <Route path="*" element={<Navigate to={'/404'} />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default Router;
