import React, { useEffect, useReducer, useRef, useState } from 'react';
import {
  CButton,
  CCol,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilArrowThickToRight, cilCalendar } from '@coreui/icons';
import moment from 'moment';
import AppDateTimeSelector from './AppDateTimeSelector';
import { useTranslation } from 'react-i18next';

interface AppTimeRangeFilterProps {
  onChange: (dateFrom: moment.Moment, dateTo: moment.Moment) => void;
  dateForm?: moment.Moment;
  dateTo?: moment.Moment;
}

const AppTimeRangeFilter = (props: AppTimeRangeFilterProps) => {
  const { onChange, dateForm, dateTo } = props;

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [from, setFrom] = useState(dateForm ? dateForm : moment().startOf('month'));
  const [to, setTo] = useState(dateTo ? dateTo : moment().endOf('day'));
  const [selectedFrom, setSelectedFrom] = useState(dateForm ? dateForm : moment().startOf('month'));
  const [selectedTo, setSelectedTo] = useState(dateTo ? dateTo : moment().endOf('day'));
  const [visible, setVisible] = useState(false);

  const [customSelectValue, setCustomSelectValue] = useState(1);
  const [customSelectType, setCustomSelectType] = useState('day');
  const customSelectInputRef = useRef<HTMLInputElement>(null);

  const [previousPeriod, setPreviousPeriod] = useState(false);
  const [previousPeriodType, setPreviousPeriodType] = useState('quater');

  const { t } = useTranslation('stats');
  const { t: tGeneral } = useTranslation();

  useEffect(() => {
    onChange(from, to);
  }, [from, to]);

  const rangeQuickselect = (value: string) => {
    let from = null,
      to = null;

    switch (value) {
      case 'today':
        from = moment().startOf('day');
        to = moment().endOf('day');
        break;
      case 'yesterday':
        from = moment().startOf('day').subtract(1, 'day');
        to = moment().endOf('day').subtract(1, 'day');
        break;
      case 'last-7-days':
        from = moment().startOf('day').subtract(6, 'day');
        to = moment().endOf('day');
        break;
      case 'this-week':
        from = moment().startOf('isoWeek');
        to = moment().endOf('isoWeek');
        break;
      case 'current-week':
        from = moment().startOf('isoWeek').subtract(1, 'week');
        to = moment().endOf('isoWeek').subtract(1, 'week');
        break;
      case 'last-week':
        from = moment().startOf('isoWeek').subtract(2, 'week');
        to = moment().endOf('isoWeek').subtract(2, 'week');
        break;
      case 'last-last-week':
        from = moment().startOf('isoWeek').subtract(3, 'week');
        to = moment().endOf('isoWeek').subtract(3, 'week');
        break;
      case 'this-4-week':
        from = moment().startOf('isoWeek').subtract(3, 'week');
        to = moment().endOf('isoWeek');
        break;
      case 'current-4-week':
        from = moment().subtract(4, 'week').startOf('isoWeek');
        to = moment().subtract(1, 'week').endOf('isoWeek');
        break;
      case 'last-4-week':
        from = moment().startOf('isoWeek').subtract(8, 'week');
        to = moment().endOf('isoWeek').subtract(5, 'week');
        break;
      case 'last-last-4-week':
        from = moment().startOf('isoWeek').subtract(12, 'week');
        to = moment().endOf('isoWeek').subtract(9, 'week');
        break;
      case 'current-month':
        from = moment().startOf('month');
        to = moment().endOf('month');
        break;
      case 'last-month':
        from = moment().startOf('month').subtract(1, 'month').startOf('month');
        to = moment().endOf('month').subtract(1, 'month').endOf('month');
        break;
      case 'last-last-month':
        from = moment().startOf('month').subtract(2, 'month').startOf('month');
        to = moment().endOf('month').subtract(2, 'month').endOf('month');
        break;
    }

    if (from !== null && to !== null) {
      setSelectedFrom(from);
      setSelectedTo(to);
      setVisible(false);
    }
  };

  const applyCustomRange = () => {
    const from = moment()
      .subtract(customSelectValue, customSelectType as moment.unitOfTime.DurationConstructor)
      .startOf(customSelectType as moment.unitOfTime.DurationConstructor);
    const to = moment().endOf(customSelectType as moment.unitOfTime.DurationConstructor);

    if (from !== null && to !== null) {
      setSelectedFrom(from);
      setSelectedTo(to);
      setVisible(false);
    }
  };

  useEffect(() => {
    const tmpFrom = selectedFrom.clone();
    const tmpTo = selectedTo.clone();

    if (previousPeriod) {
      switch (previousPeriodType) {
        case 'quater':
          setFrom(tmpFrom.subtract(1, 'quarter'));
          setTo(tmpTo.subtract(1, 'quarter'));
          break;
        case 'year':
          setFrom(tmpFrom.subtract(1, 'year'));
          setTo(tmpTo.subtract(1, 'year'));
          break;
      }
    } else {
      setFrom(tmpFrom);
      setTo(tmpTo);
    }

    forceUpdate();
  }, [previousPeriod, previousPeriodType, selectedFrom, selectedTo]);

  return (
    <CInputGroup className="mb-3">
      <CDropdown variant="input-group" autoClose="outside" visible={visible} onShow={() => setVisible(true)} direction="center" popper={false}>
        <CDropdownToggle color="secondary">
          <CIcon icon={cilCalendar} />
        </CDropdownToggle>
        <CDropdownMenu className="time-range-menu">
          <CDropdownHeader>{t('quick-select')}</CDropdownHeader>
          <CDropdownItem style={{ cursor: 'inherit' }}>
            <CRow className="custom-range-select">
              <CCol>Letzte</CCol>
              <CCol>
                <CFormInput
                  size="sm"
                  value={customSelectValue}
                  ref={customSelectInputRef}
                  onChange={(e) => {
                    const val = parseInt(e.target.value);
                    if (!isNaN(val)) {
                      setCustomSelectValue(val);
                    }

                    setTimeout(() => {
                      customSelectInputRef.current.focus();
                    }, 0);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      applyCustomRange();
                    }
                  }}
                />
              </CCol>
              <CCol>
                <CFormSelect
                  value={customSelectType}
                  onChange={(e) => setCustomSelectType(e.target.value)}
                  options={[
                    {
                      label: t('days-select'),
                      value: 'day',
                    },
                    {
                      label: t('weeks-select'),
                      value: 'week',
                    },
                    {
                      label: t('months-select'),
                      value: 'month',
                    },
                  ]}
                  size="sm"
                />
              </CCol>
              <CCol>
                <CButton color="secondary" size="sm" onClick={() => applyCustomRange()}>
                  {tGeneral('apply')}
                </CButton>
              </CCol>
            </CRow>
          </CDropdownItem>
          <CDropdownDivider />
          <CDropdownHeader>{t('day')}</CDropdownHeader>
          <CDropdownItem onClick={() => rangeQuickselect('today')}>{t('today')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('yesterday')}>{t('yesterday')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('last-7-days')}>{t('last-7-days')}</CDropdownItem>
          <CDropdownDivider />
          <CDropdownHeader>{t('week')}</CDropdownHeader>
          <CDropdownItem onClick={() => rangeQuickselect('this-week')}>{t('this-week')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('current-week')}>{t('current-week')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('last-week')}>{t('last-week')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('last-last-week')}>{t('last-last-week')}</CDropdownItem>
          <CDropdownDivider />
          <CDropdownHeader>{t('4-week')}</CDropdownHeader>
          <CDropdownItem onClick={() => rangeQuickselect('this-4-week')}>{t('this-4-week')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('current-4-week')}>{t('current-4-week')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('last-4-week')}>{t('last-4-week')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('last-last-4-week')}>{t('last-last-4-week')}</CDropdownItem>
          <CDropdownDivider />
          <CDropdownHeader>{t('month')}</CDropdownHeader>
          <CDropdownItem onClick={() => rangeQuickselect('current-month')}>{t('current-month')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('last-month')}>{t('last-month')}</CDropdownItem>
          <CDropdownItem onClick={() => rangeQuickselect('last-last-month')}>{t('last-last-month')}</CDropdownItem>
          <CDropdownDivider />
          <CDropdownHeader>{t('previous-period')}</CDropdownHeader>
          <CDropdownItem style={{ cursor: 'inherit' }}>
            <CFormCheck label={t('activate-previous-period')} checked={previousPeriod} onChange={(e) => setPreviousPeriod(e.target.checked === true)} />
          </CDropdownItem>
          <CDropdownItem style={{ cursor: 'inherit' }}>
            <CFormSelect
              value={previousPeriodType}
              onChange={(e) => setPreviousPeriodType(e.target.value)}
              options={[
                { label: t('quater'), value: 'quater' },
                { label: t('year'), value: 'year' },
              ]}
              size="sm"
            />
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
      <AppDateTimeSelector onChange={(value, date) => setFrom(moment(date))} disableIcon={true} value={from.format(tGeneral('date-format'))} />
      <CInputGroupText>
        <CIcon icon={cilArrowThickToRight} />
      </CInputGroupText>
      <AppDateTimeSelector onChange={(value, date) => setTo(moment(date))} disableIcon={true} value={to.format(tGeneral('date-format'))} />
    </CInputGroup>
  );
};

export default React.memo(AppTimeRangeFilter);
