import { Expose, Type } from 'class-transformer';
import { UserEntity } from './user.entity';

export class PasswordResetEntity {
  @Expose()
  @Type(() => UserEntity)
  user: UserEntity;

  @Expose()
  date: Date;

  @Expose()
  token: string;
}
