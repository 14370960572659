import { CreateDocumentDto, PublishDocumentDto, RevertDocumentDto, UpdateDocumentDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { DocumentHistoryModel, DocumentModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const editorApi = createApi({
  reducerPath: 'editorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/document',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    getDocument: builder.query<DocumentModel, string>({
      query: (uuid) => uuid,
      keepUnusedDataFor: 0,
    }),
    deleteDocument: builder.query<DocumentModel, string>({
      query: (uuid) => ({
        method: 'DELETE',
        url: uuid,
      }),
    }),
    copyDocument: builder.query<DocumentModel, string>({
      query: (uuid) => ({
        method: 'POST',
        url: `${uuid}/copy`,
      }),
    }),
    publishDocument: builder.query<DocumentModel, { uuid: string; dto: PublishDocumentDto }>({
      query: ({ uuid, dto }) => ({
        method: 'POST',
        url: `${uuid}/publish`,
        body: dto,
      }),
    }),
    updateDocument: builder.query<DocumentModel, { uuid: string; dto: UpdateDocumentDto }>({
      query: ({ uuid, dto }) => ({
        method: 'PUT',
        url: uuid,
        body: dto,
      }),
    }),
    createDocument: builder.query<DocumentModel, CreateDocumentDto>({
      query: (dto) => ({
        method: 'POST',
        url: '',
        body: dto,
      }),
    }),
    getDocumentHistorys: builder.query<[DocumentHistoryModel], string>({
      query: (uuid) => `${uuid}/history`,
      keepUnusedDataFor: 0,
    }),
    revertRevision: builder.query<DocumentModel, { uuid: string; dto: RevertDocumentDto }>({
      query: ({ uuid, dto }) => ({
        method: 'POST',
        url: `${uuid}/revert`,
        body: dto,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetDocumentQuery,
  useLazyPublishDocumentQuery,
  useLazyUpdateDocumentQuery,
  useLazyCreateDocumentQuery,
  useLazyDeleteDocumentQuery,
  useGetDocumentHistorysQuery,
  useLazyRevertRevisionQuery,
  useLazyCopyDocumentQuery
} = editorApi;
