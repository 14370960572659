import { NavLink } from 'react-router-dom';
import { CContainer, CHeader, CHeaderBrand, CHeaderDivider, CHeaderNav, CHeaderToggler, CNavLink, CNavItem, CImage } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilMenu } from '@coreui/icons';

import { AppBreadcrumb } from './index';
import { AppHeaderDropdown } from './header/index';
import { useAppDispatch, useAppSelector } from '../store';
import { setLoading, toggle } from '../store/slices/uiSlice';
import { isEmpty } from 'lodash';
import { useGetEnabledOrganisationsQuery, useLazyChangeContextOrgaQuery } from '../store/apis/authApi';
import AppSelect from './AppSelect';
import { OrganisationModel } from '../models';
import newscubeLogo from '../assets/newscube_wuerfel_rgb.png';

const AppHeader = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.credentials.user);
  const contextOrga = useAppSelector((state) => state.credentials.contextOrga);

  const { data: organisations } = useGetEnabledOrganisationsQuery();

  const [triggerChangeContextOrga] = useLazyChangeContextOrgaQuery();

  const onChangeOrganisation = (organisation: OrganisationModel) => {
    triggerChangeContextOrga({ uuid: organisation.uuid });
    dispatch(setLoading(true));
  };

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={() => dispatch(toggle())}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="d-md-none">
          <CImage src={newscubeLogo} style={{ maxHeight: '32px' }} />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/" as={NavLink}>
              Home
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppSelect
            options={organisations}
            value={!isEmpty(contextOrga) ? contextOrga : user.organisation}
            onChange={onChangeOrganisation}
            className="organisation-select"
          />
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
