import { Expose } from 'class-transformer';

export class SchedulerCommandEntity {
  @Expose()
  uuid?: string;

  @Expose()
  date: Date;

  @Expose()
  command: string;

  @Expose()
  commandName: string;

  @Expose()
  type: 'command' | 'event';
}
