import { useEffect, useState } from 'react';
import Select from 'react-select';
import { concat, find, intersectionWith, isArray, isEmpty, isFunction, isObject, isObjectLike, isString, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CButton, CCard, CCardBody, CCardImage, CCardTitle, CCol, CRow } from '@coreui/react';

interface AppSelectProps {
  onChange: (value: any | any[]) => void;
  value: any | any[];
  options: any[];
  isMulti?: boolean;
  isLoading?: boolean;
  allowEmpty?: boolean;
  returnOptionValue?: boolean;
  className?: string;
  valueFormatter?: (option: any) => string;
  titleFormatter?: (option: any) => string;
  imageSelector?: (option: any) => string;
  cardLayout?: boolean;
}

const AppSelect = (props: AppSelectProps) => {
  const { value, onChange, options, isMulti, isLoading, allowEmpty, returnOptionValue, valueFormatter, titleFormatter, imageSelector, className, cardLayout } =
    props;

  const [defaultValue, setDefaultValue] = useState(null);
  const [mappedOptions, setMappedOptions] = useState([]);

  const { t } = useTranslation();

  const getOptionValue = (option: any) => {
    if (isFunction(valueFormatter)) {
      return valueFormatter(option);
    }
    if (isString(option)) {
      return option;
    }
    if (!isEmpty(option.uuid)) {
      return option.uuid;
    }
    return option.value;
  };

  const getOptionTitle = (option: any) => {
    if (isFunction(titleFormatter)) {
      return titleFormatter(option);
    }
    if (isString(option)) {
      return option;
    }
    return option.title;
  };

  useEffect(() => {
    if (isEmpty(options)) {
      return;
    }
    let mapped = map(options, (option) => {
      return { value: getOptionValue(option), label: getOptionTitle(option) };
    });
    if (allowEmpty) {
      mapped = concat([{ value: '', label: '--' }], mapped);
    }
    setMappedOptions(mapped);

    if (!isEmpty(value)) {
      switch (true) {
        case isArray(value):
          setDefaultValue(intersectionWith(mapped, value, (a, b) => a.value == getOptionValue(b)));
          break;
        case isObject(value):
          setDefaultValue(find(mapped, (m) => m.value == getOptionValue(value)));
          break;
        default:
          setDefaultValue(find(mapped, (m) => m.value == value));
          break;
      }
    } else {
      if (allowEmpty) {
        setDefaultValue({ value: '', label: '--' });
      } else {
        setDefaultValue(null);
      }
    }
  }, [options, value]);

  const internalOnChange = (values: any) => {
    let entitys = [];
    if (isArray(values)) {
      entitys = intersectionWith(options, values, (a, b: any) => getOptionValue(a) == b.value);
    } else {
      entitys = find(options, (s) => getOptionValue(s) == values.value);
    }

    onChange(returnMapper(entitys));
  };

  const returnMapper = (values: any) => {
    if (!returnOptionValue) {
      return values;
    }

    if (isArray(values)) {
      return map(values, (v) => getOptionValue(v));
    }
    return getOptionValue(values);
  };

  const getImage = (option: any) => {
    let url = null;
    if (isFunction(imageSelector)) {
      url = imageSelector(option);
    }

    if (isEmpty(url)) return <></>;

    return <CCardImage src={url} />;
  };

  if (cardLayout) {
    return (
      <CRow md={{ cols: 4 }} xs={{ cols: 1 }}>
        {map(options, (option, key) => {
          return (
            <CCol key={key}>
              <CCard>
                {getImage(option)}
                <CCardBody>
                  <CCardTitle>{getOptionTitle(option)}</CCardTitle>
                  <CButton
                    onClick={() => {
                      internalOnChange({ value: getOptionValue(option), label: getOptionTitle(option) });
                    }}
                    color={getOptionValue(option) == getOptionValue(value) ? 'primary' : 'secondary'}
                  >
                    {getOptionValue(option) == getOptionValue(value) ? t('selected') : t('select')}
                  </CButton>
                </CCardBody>
              </CCard>
            </CCol>
          );
        })}
      </CRow>
    );
  }

  return (
    <Select
      placeholder={t('please-select')}
      value={defaultValue}
      isMulti={isMulti}
      name="colors"
      options={mappedOptions}
      className={className ?? 'basic-multi-select'}
      classNamePrefix="select"
      onChange={internalOnChange}
      noOptionsMessage={() => t('no-options')}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
  );
};

export default AppSelect;
