import { Expose, Type } from 'class-transformer';
import { StreamEntity } from '../stream';

export class HubSettingsEntity {
  @Expose()
  key: string;
  @Expose()
  notificationUrl?: string;
  @Expose()
  allowMultipleStreams?: boolean;
}

export class HubEntity {
  @Expose()
  uuid?: string;
  @Expose()
  title: string;
  @Expose()
  type: string;
  @Expose()
  description: string;
  @Expose()
  acronym?: string;
  @Expose()
  color?: string;
  @Expose()
  isActive: boolean;
  @Expose()
  @Type(() => HubSettingsEntity)
  settings: HubSettingsEntity;
  @Expose()
  @Type(() => StreamEntity)
  streams?: Array<StreamEntity>;
  @Expose()
  createdAt?: Date;
  @Expose()
  updatedAt?: Date;
  @Expose()
  deletedAt?: Date;

  static typeOptions: Array<{ uuid: string, title: string }> = [
    { uuid: 'web', title: 'Web Export' },
    { uuid: 'telegram', title: 'Telegram' }
  ];
}
