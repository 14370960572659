import { useContext, useEffect } from 'react';
import defineAbilityFor, { AbilityContext } from './context/ability.context';
import './scss/style.scss';
import { useAppDispatch, useAppSelector } from './store';
import { useLazyProfileQuery } from './store/apis/authApi';
import { logout } from './store/slices/credentialsSlice';
import { SocketContext } from './context/socket.context';
import { isEmpty } from 'lodash';
import Router from './Router';

let profileInterval = null;
let versionInterval = null;

const App = () => {
  const authenticated = useAppSelector((state) => state.credentials.authenticated);
  const token = useAppSelector((state) => state.credentials.token);
  const user = useAppSelector((state) => state.credentials.user);
  const [triggerProfile] = useLazyProfileQuery();
  const dispatch = useAppDispatch();
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (authenticated && token) {
      if (profileInterval === null) {
        const decodedJwt = JSON.parse(atob(token?.split('.')[1]));
        const expireIn = decodedJwt.exp * 1000 - Date.now();

        if (expireIn < 0) {
          console.log('Die Sitzung ist abgelaufen [' + Math.floor(expireIn / 1000) + 's]');
          dispatch(logout());
          clearInterval(profileInterval);
          profileInterval = null;
        }
        console.log('Sitzung läuft ab in [' + Math.floor(expireIn / 1000) + 's]');
        triggerProfile();
        socket.emit('identity', { token });
        profileInterval = setInterval(
          () => {
            triggerProfile();
          },
          Math.floor(expireIn / 2),
        );
      }
    } else {
      console.log('Profile Interval reset');
      clearInterval(profileInterval);
      profileInterval = null;
      dispatch(logout());
    }

    return () => {
      clearInterval(profileInterval);
      profileInterval = null;
    };
  }, [authenticated]);

  useEffect(() => {
    socket.emit('identity', { token });
  }, [token]);

  const checkAppVersion = () => {
    fetch('/assets/version.json').then((res) => {
      res.json().then((json) => {
        const newestVersion = json.version;
        const browserVersion = localStorage.getItem('app-version');
        localStorage.setItem('app-version', newestVersion);

        if (!isEmpty(browserVersion) && browserVersion !== newestVersion) {
          console.log('New version detected. Reloading...');

          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
              for (let registration of registrations) {
                registration.unregister();
              }
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        }
      });
    });
  };

  useEffect(() => {
    if (versionInterval === null) {
      checkAppVersion();
      versionInterval = setInterval(checkAppVersion, 1000 * 60 * 5);
    }

    return () => {
      clearInterval(versionInterval);
      versionInterval = null;
    };
  }, []);

  return (
    <AbilityContext.Provider value={defineAbilityFor(user)}>
      <Router />
    </AbilityContext.Provider>
  );
};

export default App;
