import { IsNotEmpty, IsOptional } from 'class-validator';
import { OrganisationDto } from '../organisation';
import { StreamDto } from '../stream';
import { HubSettingsDto } from './hub-settings.dto';

export class HubDto {
  @IsOptional()
  readonly uuid?: string;
  @IsNotEmpty()
  readonly title: string;
  @IsNotEmpty()
  readonly type: string;
  @IsOptional()
  readonly description?: string;
  @IsOptional()
  readonly acronym?: string;
  @IsOptional()
  readonly color?: string;
  @IsOptional()
  readonly isActive?: boolean;
  @IsNotEmpty()
  readonly settings: HubSettingsDto;
  // @IsOptional()
  // readonly streams?: StreamDto[];
}
