import { Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

export class DocumentContentDto {
  @Expose()
  content: string;

  @Expose()
  @IsNotEmpty()
  documentTemplateSlotUuid: string;
}
