import { AdboxTemplateDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { AdboxTemplateModel, DocumentTemplateModel, DocumentTemplateSlotModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const adboxTemplateApi = createApi({
  reducerPath: 'adboxTemplateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/adboxtemplate',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    createAdboxTemplate: builder.query<AdboxTemplateModel, AdboxTemplateDto>({
      query: (dto) => ({
        method: 'POST',
        url: '',
        body: dto,
      }),
    }),
    getAdboxTemplate: builder.query<AdboxTemplateModel, string>({
      query: (uuid) => uuid,
      keepUnusedDataFor: 0,
    }),
    updateAdboxTemplate: builder.query<AdboxTemplateModel, { uuid: string; dto: AdboxTemplateDto }>({
      query: ({ uuid, dto }) => ({
        method: 'PUT',
        url: uuid,
        body: dto,
      }),
    }),
    deleteAdboxTemplate: builder.query<AdboxTemplateModel, string>({
      query: (uuid) => ({
        method: 'DELETE',
        url: uuid,
      }),
    }),
    copyAdboxTemplate: builder.query<AdboxTemplateModel, string>({
      query: (uuid) => ({
        method: 'POST',
        url: `copy/${uuid}`,
      }),
    }),
    getAdboxTemplateSlot: builder.query<AdboxTemplateModel, string>({
      query: (uuid) => uuid,
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyCreateAdboxTemplateQuery,
  useGetAdboxTemplateQuery,
  useLazyGetAdboxTemplateQuery,
  useLazyUpdateAdboxTemplateQuery,
  useLazyDeleteAdboxTemplateQuery,
  useLazyGetAdboxTemplateSlotQuery,
  useLazyCopyAdboxTemplateQuery,
} = adboxTemplateApi;
