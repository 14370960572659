import { Exclude, Expose, Type } from 'class-transformer';
import { DocumentTemplateSlotEntity } from '../documenttemplate';
import type { DocumentEntity } from './document.entity';

export class DocumentLinkEntity {
  @Expose()
  uuid: string;

  @Expose()
  title?: string;

  @Expose()
  url: string;

  @Expose()
  @Type(() => DocumentTemplateSlotEntity)
  documentTemplateSlot?: DocumentTemplateSlotEntity;
}

export class DocumentLinkPublishedEntity extends DocumentLinkEntity {}

export class ExternalDocumentLinkPublishedEntity extends DocumentLinkPublishedEntity {
  @Exclude()
  documentTemplateSlot?: DocumentTemplateSlotEntity;
}
