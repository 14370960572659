import { Expose, Type } from 'class-transformer';
import { MediaEntity } from './media.entity';
import type { MediaEntity as MediaEntityType } from './media.entity';
export class MediaMetaEntity {
  @Expose()
  size?: number;

  @Expose()
  @Type(() => MediaEntity)
  thumbnail?: MediaEntityType;

  @Expose()
  currentChunkIndex?: number;

  @Expose()
  totalChunks?: number;

  @Expose()
  uploadId?: string;

  @Expose()
  mimeType?: string;

  @Expose()
  ext?: string;
}

export class MediaPartEntity {
  @Expose()
  etag: string;

  @Expose()
  partNumber: number;
}
