/* eslint-disable @typescript-eslint/ban-types */
import { CToast, CToastBody, CToastClose, CToaster } from '@coreui/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../store';
import { addToast } from '../store/slices/uiSlice';

type AlertContextProps = {
  showSuccess: Function;
  showError: Function;
  showInfo: Function;
};

const AlertContext = React.createContext<Partial<AlertContextProps>>({});
AlertContext.displayName = 'AlertContext';

interface Alert {
  message: string;
  title?: string;
  color: string;
}

const toastElement = (alert: Alert) => {
  return (
    <CToast autohide={true} visible={true} color={alert.color} className="text-white align-items-center" style={{ zIndex: 99999 }}>
      <div className="d-flex" style={{ zIndex: 99999 }}>
        <CToastBody>{alert.message}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  );
};

function AlertProvider({ children }) {
  const dispatch = useDispatch();
  const uiToast = useAppSelector((state) => state.ui.toast);

  const showSuccess = (message: string, title?: string) => {
    dispatch(addToast({ message, title, color: 'success' }));
  };

  const showError = (message: string, title?: string) => {
    dispatch(addToast({ message, title, color: 'danger' }));
  };

  const showInfo = (message: string, title?: string) => {
    dispatch(addToast({ message, title, color: 'info' }));
  };

  const value = { showSuccess, showError, showInfo };
  return (
    <AlertContext.Provider value={value}>
      <CToaster push={uiToast ? toastElement(uiToast) : null} placement="top-end" style={{ zIndex: 99999 }} />
      {children}
    </AlertContext.Provider>
  );
}

export { AlertProvider };
export default AlertContext;
