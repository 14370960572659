import { Expose, Type } from 'class-transformer';
import { StreamEntity } from '../stream';
import { UserEntity } from '../user';
import { DocumentEntity } from './document.entity';
import type { DocumentEntity as DocumentEntityType } from './document.entity';

export class DocumentHistoryEntity {
  @Expose()
  uuid?: string;

  @Expose()
  @Type(() => DocumentEntity)
  document: DocumentEntityType;

  @Expose()
  @Type(() => StreamEntity)
  streams?: StreamEntity[];

  @Expose()
  adbox?: boolean;

  @Expose()
  @Type(() => UserEntity)
  user: UserEntity;

  @Expose()
  publishedAt?: Date;

  @Expose()
  createdAt?: Date;
}
