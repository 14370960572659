import { IsNotEmpty } from 'class-validator';
import { DocumentTemplateFilterDto } from './documenttemplatefilter.dto';
import { DocumentTemplateSlotDto } from './documenttemplateslot.dto';

export class DocumentTemplateDto {
  readonly uuid?: string;
  @IsNotEmpty()
  readonly title: string;
  readonly description: string;
  readonly slots: DocumentTemplateSlotDto[];
  readonly filter?: DocumentTemplateFilterDto[];
  readonly author?: string;
}
