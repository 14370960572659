import { Expose, Type } from 'class-transformer';
import { DocumentEntity } from './document.entity';

export class DocumentHashEntity {
  @Expose()
  uuid?: string;

  @Expose()
  hash: string;

  @Expose()
  contentHash: string;

  @Expose()
  @Type(() => DocumentEntity)
  document: DocumentEntity;
}
