import { TagDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { TagModel } from '../../models';

export const tagApi = createApi({
  reducerPath: 'tagApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/tag',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    createTag: builder.query<TagModel, TagDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
      keepUnusedDataFor: 0,
    }),
    getTag: builder.query<TagModel, string>({
      query: (uuid) => ({ url: uuid }),
      keepUnusedDataFor: 0,
    }),
    updateTag: builder.query<TagModel, { uuid: string; dto: TagDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: uuid, body: dto }),
    }),
    deleteTag: builder.query<TagModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: uuid }),
    }),
  }),
});

export const { useGetTagQuery, useLazyCreateTagQuery, useLazyUpdateTagQuery, useLazyDeleteTagQuery } = tagApi;
