import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { useEffect } from 'react';

export function PrivateRoute({ children, ...rest }) {
  const authenticated = useAppSelector((state) => state.credentials.authenticated);
  const navigation = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      navigation('/login');
    }
  });

  if (!authenticated) {
    return <></>;
  }

  return children;
}
