import { Editor } from '@tinymce/tinymce-react';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { css } from '@codemirror/lang-css';
import { javascript } from '@codemirror/lang-javascript';
import { json } from '@codemirror/lang-json';
import { useState } from 'react';
import MediaSelectModal from './media/MediaSelectModal';
import { environment } from '../environments/environment';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';

interface AppEditorProps {
  id?: string;
  onChange: (value: string) => void;
  value: string;
  sourceOnly?: boolean;
  sourceExtension?: 'html' | 'css' | 'javascript' | 'json';
  required?: boolean;
  invalid?: boolean;
  disableStickyToolbar?: boolean;
}

const AppEditor = (props: AppEditorProps) => {
  const { id, onChange, value, sourceOnly, invalid, sourceExtension, disableStickyToolbar } = props;
  const [mediaDialogVisible, setMediaDialogVisible] = useState(false);
  const [mediaDialogFilePickerVisible, setMediaDialogFilePickerVisible] = useState(false);
  const [editorUuid] = useState(uuid());
  const { t, i18n } = useTranslation();

  if (sourceOnly) {
    const extensions = [];
    switch (sourceExtension) {
      case 'html':
      default:
        extensions.push(html());
        break;
      case 'css':
        extensions.push(css());
        break;
      case 'javascript':
        extensions.push(javascript());
        break;
      case 'json':
        extensions.push(json());
        break;
    }

    return (
      <CodeMirror
        value={value}
        extensions={extensions}
        onChange={(value, viewUpdate) => {
          onChange(value);
        }}
      />
    );
  }

  window.addEventListener(`imageGalleryTriggerOpen-${editorUuid}`, () => {
    setMediaDialogVisible(true);
  });

  window.addEventListener(`imageGalleryTriggerOpen-${editorUuid}-filePicker`, () => {
    setMediaDialogFilePickerVisible(true);
  });

  const editorId = id ?? uuid();

  return (
    <div className={invalid ? 'invalid' : ''}>
      <Editor
        id={editorId}
        tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
        value={value}
        onSetContent={(e) => {
          if (e.set && !e.initial && !e.selection) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
            return;
          }
        }}
        onBeforeSetContent={(e) => {
          if (e.set && !e.initial && !e.selection) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
            return;
          }
        }}
        onEditorChange={(val, e) => {
          onChange(val);
        }}
        plugins="code paste table link hr lists wordcount media quickbars autoresize"
        toolbar={[
          'undo redo | blocks | forecolor fontselect fontsizeselect | bold italic underline strikethrough removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | blockquote hr | link ImageGallery imageoptions-toolbar media | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code',
        ]}
        init={{
          setup: function (editor) {
            /**
             * ImageOptions
             */

            const imageOptionsDialogConfig = {
              title: t('editor.image-options'),
              body: {
                type: 'panel',
                items: [
                  {
                    type: 'input',
                    name: 'alt',
                    label: t('editor.alt'),
                  },
                  {
                    type: 'input',
                    name: 'title',
                    label: t('translation.title'),
                  },
                  {
                    type: 'grid',
                    columns: 2,
                    items: [
                      {
                        type: 'input',
                        name: 'width',
                        label: t('editor.width'),
                      },
                      {
                        type: 'input',
                        name: 'height',
                        label: t('editor.height'),
                      },
                    ],
                  },
                  {
                    type: 'input',
                    name: 'margin',
                    label: t('editor.margin'),
                  },
                ],
              },
              buttons: [
                {
                  type: 'cancel',
                  name: 'closeButton',
                  text: t('translation.cancel'),
                },
                {
                  type: 'submit',
                  name: 'submitButton',
                  text: t('translation.save'),
                  buttonType: 'primary',
                },
              ],
              initialData: {},
              onSubmit: (api) => {
                const data: any = api.getData();

                const selectedNode = editor.selection.getNode();
                if (!isEmpty(data.alt)) selectedNode.setAttribute('alt', data.alt);
                else selectedNode.removeAttribute('alt');
                if (!isEmpty(data.title)) selectedNode.setAttribute('title', data.title);
                else selectedNode.removeAttribute('title');
                if (!isEmpty(data.width)) selectedNode.setAttribute('width', data.width);
                else selectedNode.removeAttribute('width');
                if (!isEmpty(data.height)) selectedNode.setAttribute('height', data.height);
                else selectedNode.removeAttribute('height');
                if (!isEmpty(data.margin)) selectedNode.setAttribute('hspace', data.margin);
                else selectedNode.removeAttribute('hspace');

                api.close();
              },
            };

            const imageOptionsAction = () => {
              // @ts-ignore
              const api = editor.windowManager.open(imageOptionsDialogConfig);
              const data = api.getData();
              const selectedNode = editor.selection.getNode();

              data.alt = selectedNode.getAttribute('alt') ?? '';
              data.title = selectedNode.getAttribute('title') ?? '';
              data.height = selectedNode.getAttribute('height') ?? '';
              data.width = selectedNode.getAttribute('width') ?? '';
              data.margin = selectedNode.getAttribute('hspace') ?? '';

              api.setData(data);
            };

            editor.ui.registry.addButton('imageoptions', {
              icon: 'edit-image',
              tooltip: t('editor.image-options'),
              onAction: imageOptionsAction,
            });

            editor.ui.registry.addButton('imageoptions-toolbar', {
              icon: 'edit-image',
              tooltip: t('editor.image-options'),
              enabled: false,
              onAction: imageOptionsAction,
              onSetup: (buttonApi) => {
                const editorEventCallback = (eventApi) => {
                  buttonApi.setEnabled(eventApi.element.nodeName.toLowerCase() === 'img');
                };
                editor.on('NodeChange', editorEventCallback);
                return () => editor.off('NodeChange', editorEventCallback);
              },
            });

            /**
             * Gallery
             */

            window.addEventListener(`imageGalleryResult-${editorUuid}`, (result: any) => {
              if (result && result.detail && result.detail.exportUrl) {
                editor.insertContent('<img src="' + result.detail.exportUrl + '" />');
              }
            });

            /* Add a button that opens a window */
            editor.ui.registry.addButton('ImageGallery', {
              icon: 'image',
              tooltip: 'Galerie',
              onAction: function () {
                window.dispatchEvent(new Event(`imageGalleryTriggerOpen-${editorUuid}`));
              },
            });
          },
          formats: {
            imageMargin: { inline: 'img', styles: { margin: '%value' } },
          },
          language: i18n.language,
          height: '500px',
          menubar: false,
          quickbars_insert_toolbar: false,
          quickbars_image_toolbar: 'alignleft aligncenter alignright | imageoptions',
          min_height: 500,
          toolbar_sticky: !disableStickyToolbar,
          toolbar_sticky_offset: disableStickyToolbar ? 0 : 95,
          extended_valid_elements:
            'a[class|name|href|target|title|onclick|rel|style],script[type|src|class|name],iframe[src|style|width|height|scrolling|marginwidth|marginheight|frameborder],img[class|src|border|alt|title|hspace|vspace|width|height|align|data-margin|onmouseover|onmouseout|name|style],span[class|id|name|style],div[class|id|name|style],strong,u,i,hr',
          file_picker_callback: (callback, value, meta) => {
            window.dispatchEvent(new Event(`imageGalleryTriggerOpen-${editorUuid}-filePicker`));

            window.addEventListener(`imageGalleryResult-${editorUuid}-filePicker`, (result: any) => {
              if (result && result.detail && result.detail.exportUrl) {
                callback(result.detail.exportUrl, { width: '100%', height: 'auto' });
              }
            });
          },
        }}
      />
      <MediaSelectModal
        visible={mediaDialogVisible}
        onSelect={(media) => {
          window.dispatchEvent(
            new CustomEvent(`imageGalleryResult-${editorUuid}`, { detail: { ...media, exportUrl: `${environment.cdnHost}/media/${media.uuid}` } }),
          );
          setMediaDialogVisible(false);
        }}
        onDismiss={() => setMediaDialogVisible(false)}
      />
      <MediaSelectModal
        visible={mediaDialogFilePickerVisible}
        onSelect={(media) => {
          window.dispatchEvent(
            new CustomEvent(`imageGalleryResult-${editorUuid}-filePicker`, { detail: { ...media, exportUrl: `${environment.cdnHost}/media/${media.uuid}` } }),
          );
          setMediaDialogFilePickerVisible(false);
        }}
        onDismiss={() => setMediaDialogFilePickerVisible(false)}
      />
    </div>
  );
};
export default AppEditor;
