import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganisationModel, UserModel } from '../../models';

interface CredentialState {
  user: UserModel;
  token: string;
  authenticated: boolean;
  contextOrga: OrganisationModel;
}

const initialState: CredentialState = {
  user: null,
  token: null,
  authenticated: false,
  contextOrga: null,
};

export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.authenticated = true;
    },
    setContextOrga: (state, action: PayloadAction<OrganisationModel>) => {
      state.contextOrga = action.payload;
      window.location.reload();
    },
    logout: (state) => {
      state.authenticated = false;
      state.token = null;
      state.user = null;
      state.contextOrga = null;
    },
  },
});

export const { setUser, setToken, setContextOrga, logout } = credentialsSlice.actions;

export default credentialsSlice.reducer;
