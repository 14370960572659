import { RoleDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { CaslModel, RoleModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const caslApi = createApi({
  reducerPath: 'caslApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/casl',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    getCasl: builder.query<CaslModel, void>({
      query: () => ({ method: 'GET', url: '' }),
      keepUnusedDataFor: 0,
    }),
    getRole: builder.query<RoleModel, string>({
      query: (uuid) => ({ method: 'GET', url: `role/${uuid}` }),
      keepUnusedDataFor: 0,
    }),
    createRole: builder.query<RoleModel, RoleDto>({
      query: (dto) => ({ method: 'POST', url: 'role', body: dto }),
    }),
    updateRole: builder.query<RoleModel, { uuid: string; dto: RoleDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: `role/${uuid}`, body: dto }),
    }),
    deleteRole: builder.query<RoleModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: `role/${uuid}` }),
    }),
    getRoles: builder.query<RoleModel[], void>({
      query: () => ({ method: 'GET', url: `roles` }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetCaslQuery, useGetRoleQuery, useLazyCreateRoleQuery, useLazyUpdateRoleQuery, useLazyDeleteRoleQuery, useGetRolesQuery } = caslApi;
