import { Expose } from 'class-transformer';

export class DocumentTemplateSlotEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  internalTitle?: string;

  @Expose()
  contentType: string;

  @Expose()
  fieldType: string;

  @Expose()
  required: boolean;

  @Expose()
  hidden: boolean;

  @Expose()
  defaultValue: string;

  @Expose()
  options: string;

  @Expose()
  position: number;
}
