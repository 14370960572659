import { Expose } from 'class-transformer';

export class DocumentLinkDto {
  @Expose()
  uuid?: string;

  @Expose()
  title?: string;

  @Expose()
  url?: string;

  @Expose()
  documentUuid?: string;

  @Expose()
  documentTemplateSlotUuid?: string;
}
