import { LinkDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { LinkModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const linkApi = createApi({
  reducerPath: 'linkApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/link',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    getLink: builder.query<LinkModel, string>({
      query: (uuid) => ({ url: uuid }),
      keepUnusedDataFor: 0,
    }),
    createLink: builder.query<LinkModel, LinkDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
    }),
    updateLink: builder.query<LinkModel, { uuid: string; dto: LinkDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: uuid, body: dto }),
    }),
    deleteLink: builder.query<LinkModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: uuid }),
    }),
  }),
});

export const { useGetLinkQuery, useLazyCreateLinkQuery, useLazyUpdateLinkQuery, useLazyDeleteLinkQuery } = linkApi;
