import { Expose } from 'class-transformer';

export class AbilityEntity {
  @Expose()
  action: string;

  @Expose()
  subject: string;
}

export class CaslEntity {
  @Expose()
  actions: string[];

  @Expose()
  subjects: string[];
}
