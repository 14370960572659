import { CButton, CImage } from '@coreui/react';
import { useContext, useRef } from 'react';
import AppTable, { AppTableHandler } from '../AppTable';
import moment from 'moment';
import AppConfirmButton from '../AppConfirmButton';
import { useLazyDeleteMediaQuery } from '../../store/apis/mediaApi';
import AlertContext from '../AppAlertProvider';
import { environment } from '../../environments/environment';
import { PolicySubject } from '@nccore/shared';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import CIcon from '@coreui/icons-react';
import { cilFile } from '@coreui/icons';
import { ColumnDef } from '@tanstack/react-table';
import { MediaModel } from '../../models';

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

interface MediaListComponentProps {
  onSelect?: (row: any) => void;
}

const MediaListComponent = (props: MediaListComponentProps) => {
  const { onSelect } = props;
  const alert = useContext(AlertContext);
  const tableRef = useRef<AppTableHandler>(null);
  const [triggerDelete] = useLazyDeleteMediaQuery();

  const { t } = useTranslation('media');
  const { t: tGeneral } = useTranslation();

  const columns: ColumnDef<MediaModel>[] = [
    {
      header: t('media'),
      cell: ({ row }) => {
        if (!isEmpty(row.original.meta?.thumbnail)) {
          return <CImage src={`${environment.cdnHost}/media/${row.original.meta.thumbnail.uuid}`} style={{ maxHeight: '50px' }} />;
        }
        if (row.original.meta?.mimeType === 'application/pdf') {
          return <CIcon icon={cilFile} />;
        }
        return <CImage src={`${environment.cdnHost}/media/${row.original.uuid}`} style={{ maxHeight: '50px' }} />;
      },
      enableSorting: false,
    },
    {
      id: 'originalName',
      header: t('name'),
      accessorKey: 'originalName',
      cell: ({ row }) => {
        let cdnMediaUrl = `${environment.cdnHost}/media/${row.original.uuid}`;

        return (
          <a href={cdnMediaUrl} target="_blank" title={cdnMediaUrl}>
            {row.original.originalName}
          </a>
        );
      },
      enableSorting: true,
    },
    {
      id: 'meta.mimeType',
      accessorKey: 'meta.mimeType',
      header: t('type'),
    },
    {
      id: 'meta.size',
      header: t('size'),
      accessorKey: 'meta.size',
      cell: ({ row }) => formatBytes(row.original.meta.size ?? 0, 2),
    },
    {
      id: 'createdAt',
      header: tGeneral('created-at'),
      accessorKey: 'createdAt',
      cell: ({ row }) => moment(row.original.createdAt).format(tGeneral('date-hour-format')),
    },
    {
      header: tGeneral('actions'),
      cell: ({ row }) => {
        return (
          <div>
            <CButton
              size="sm"
              onClick={() => onSelect(row.original)}
              style={{
                display: typeof onSelect !== 'function' ? 'none' : 'inline-block',
              }}
              className="mr-3"
              color="primary"
            >
              {tGeneral('select')}
            </CButton>
            <AppConfirmButton
              size="sm"
              buttonColor="danger"
              buttonText={tGeneral('delete')}
              modalTitle={tGeneral('delete.message', { type: t('media') })}
              modalBody={tGeneral('delete.question', { type: t('media'), pronoun: tGeneral('dieses') })}
              onConfirm={async () => {
                await triggerDelete(row.original.uuid);
                alert.showSuccess(tGeneral('deleted.success', { type: t('media') }));
                tableRef.current.reloadData();
              }}
              policySubject={PolicySubject.Media}
            />
          </div>
        );
      },
      enableSorting: false,
    },
  ];

  return (
    <AppTable
      requestUrl="/media/list"
      columns={columns}
      ref={tableRef}
      defaultSortField={'createdAt'}
      defaultSortDirection={'desc'}
      options={{
        filterFields: ['originalName', 'uuid', 'meta.mimeType'],
        serverSide: true,
      }}
    />
  );
};

export default MediaListComponent;
