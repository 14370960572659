import { DocumentEntity, DocumentHistoryEntity, DocumentLinkEntity, StreamEntity, UserEntity } from '../entities';
import { BaseCRUDEvent } from './base.event';

export class DocumentCreatedEvent extends BaseCRUDEvent<DocumentEntity> {}

export class DocumentUpdatedEvent extends BaseCRUDEvent<DocumentEntity> {}

export class DocumentInternalUpdatedEvent extends BaseCRUDEvent<DocumentEntity> {}

export class DocumentDeletedEvent extends BaseCRUDEvent<DocumentEntity> {}

export class DocumentReadedEvent extends BaseCRUDEvent<DocumentEntity> {}

export class DocumentPublishedEvent extends BaseCRUDEvent<DocumentEntity> {
  publishedAt: Date;
  streams: StreamEntity[];

  constructor(entity: DocumentEntity, producer: UserEntity = null, publishedAt: Date, streams: StreamEntity[]) {
    super(entity, producer);
    this.publishedAt = publishedAt;
    this.streams = streams;
  }
}

export class DocumentHistoryCreatedEvent extends BaseCRUDEvent<DocumentHistoryEntity> {}

export class DocumentLinkCreatedEvent extends BaseCRUDEvent<DocumentLinkEntity> {}

export class DocumentLinkUpdatedEvent extends BaseCRUDEvent<DocumentLinkEntity> {}
