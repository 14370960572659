import { AdboxContentDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { AdboxModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const adboxContentApi = createApi({
  reducerPath: 'adboxContentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/adbox-content',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    createContent: builder.query<AdboxModel, AdboxContentDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
    }),
  }),
});

export const { useLazyCreateContentQuery } = adboxContentApi;
