import { useAbility } from '@casl/react';
import { cilPlus } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CTooltip } from '@coreui/react';
import { PolicyAction, PolicySubject } from '@nccore/shared';
import { isEmpty } from 'lodash';
import { AbilityContext } from '../context/ability.context';

interface AppAddButtonProps {
  onClick: () => void;
  title: string;
  policySubject?: PolicySubject;
}
const AppAddButton = (props: AppAddButtonProps) => {
  const { onClick, title, policySubject } = props;
  const userAbility = useAbility(AbilityContext);

  const can = isEmpty(policySubject) ? true : userAbility.can(PolicyAction.Create, policySubject) || userAbility.can(PolicyAction.Manage, policySubject);

  return can ? (
    <CTooltip content={title} placement="left">
      <CButton size="sm" variant="outline" color="primary" onClick={onClick}>
        <CIcon icon={cilPlus}></CIcon>
      </CButton>
    </CTooltip>
  ) : (
    <></>
  );
};
export default AppAddButton;
