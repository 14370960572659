import { Expose, Type } from 'class-transformer';
import { DocumentTemplateEntity } from '../documenttemplate';
import { OrganisationEntity } from '../organisation';
import { StreamEntity } from '../stream';
import { UserEntity } from '../user';

export class ImporterEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  className: string;

  @Expose()
  parameters: string;

  @Expose()
  @Type(() => OrganisationEntity)
  organisation: OrganisationEntity;

  @Expose()
  @Type(() => DocumentTemplateEntity)
  documentTemplate: DocumentTemplateEntity;

  @Expose()
  @Type(() => StreamEntity)
  streams: StreamEntity[];

  @Expose()
  @Type(() => UserEntity)
  user: UserEntity;

  @Expose()
  interval?: number;

  @Expose()
  webhook?: boolean;

  @Expose()
  isActive: boolean;

  @Expose()
  deletedAt?: Date;
}
