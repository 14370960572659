import { Expose } from 'class-transformer';
import { IsOptional } from 'class-validator';

export class ImporterJobDto {
  @Expose()
  @IsOptional()
  uuid?: string;
  @Expose()
  importerUuid: string;
  @Expose()
  result: string;
  @Expose()
  error: boolean;
}
