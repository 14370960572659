import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';

interface EditorTagComponent {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  invalid?: boolean;
}

const EditorTagComponent = (props: EditorTagComponent) => {
  const { value, onChange } = props;

  const [tags, setTags] = useState([]);

  const tagMapper = (tag) => {
    if (isEmpty(tag) || isEmpty(tag.value) || isEmpty(tag.label)) {
      return null;
    }

    const value = tag.value['#text'] ?? tag.value;
    const label = tag.label['#text'] ?? tag.label;
    return { label, value };
  };

  useEffect(() => {
    if (isEmpty(value)) {
      return;
    }

    try {
      const parsed = JSON.parse(value);
      if (!isEmpty(parsed)) {
        setTags(parsed.map(tagMapper));
      }
    } catch (error) {}
  }, [value]);

  const handleChange = (newValue: OnChangeValue<{ label: string; value: string }, true>, actionMeta: ActionMeta<{ label: string; value: string }>) => {
    const tmp = newValue.map(tagMapper);
    setTags(tmp);
    onChange(JSON.stringify(tmp));
  };

  return <CreatableSelect isMulti onChange={handleChange} value={tags} />;
};

export default EditorTagComponent;
