import { SearchOptionsDto, StatsListDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { TableResultModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const tableApi = createApi({
  reducerPath: 'tableApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost,
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    fetchList: builder.query<TableResultModel, { url: string; dto: SearchOptionsDto | StatsListDto }>({
      query: ({ url, dto }) => ({
        method: 'POST',
        url,
        body: dto,
      }),
    }),
  }),
});

export const { useFetchListQuery, useLazyFetchListQuery } = tableApi;
