import { DocumentLayoutDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { DocumentLayoutModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const documentLayoutApi = createApi({
  reducerPath: 'documentLayoutApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/documentlayout',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    createDocumentLayout: builder.query<DocumentLayoutModel, DocumentLayoutDto>({
      query: (dto) => ({
        method: 'POST',
        url: '',
        body: dto,
      }),
    }),
    getDocumentLayout: builder.query<DocumentLayoutModel, string>({
      query: (uuid) => uuid,
      keepUnusedDataFor: 0,
    }),
    updateDocumentLayout: builder.query<DocumentLayoutModel, { uuid: string; dto: DocumentLayoutDto }>({
      query: ({ uuid, dto }) => ({
        method: 'PUT',
        url: uuid,
        body: dto,
      }),
    }),
    deleteDocumentLayout: builder.query<DocumentLayoutModel, string>({
      query: (uuid) => ({
        method: 'DELETE',
        url: uuid,
      }),
    }),
  }),
});

export const {
  useLazyCreateDocumentLayoutQuery,
  useGetDocumentLayoutQuery,
  useLazyGetDocumentLayoutQuery,
  useLazyUpdateDocumentLayoutQuery,
  useLazyDeleteDocumentLayoutQuery,
} = documentLayoutApi;
