import uiSlice, { addToast } from './slices/uiSlice';
import { save, load } from 'redux-localstorage-simple';
import { configureStore, createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import tableSlice from './slices/tableSlice';
import organisationSlice from './slices/organisationSlice';
import { editorApi } from './apis/editorApi';
import { instanceToPlain } from 'class-transformer';
import { hubApi } from './apis/hubApi';
import { documentTemplateApi } from './apis/documentTemplateApi';
import { userApi } from './apis/userApi';
import { authApi } from './apis/authApi';
import credentialsSlice from './slices/credentialsSlice';
import { tableApi } from './apis/tableApi';
import { statsApi } from './apis/statsApi';
import { organisationApi } from './apis/organisationApi';
import { mediaApi } from './apis/mediaApi';
import { streamApi } from './apis/streamApi';
import { linkApi } from './apis/linkApi';
import { caslApi } from './apis/caslApi';
import { authorApi } from './apis/authorApi';
import { documentLayoutApi } from './apis/documentLayoutApi';
import { tagApi } from './apis/tagApi';
import { inputFilterApi } from './apis/inputFilterApi';
import { importerApi } from './apis/importerApi';
import { configApi } from './apis/configApi';
import { adboxApi } from './apis/adboxApi';
import { adboxTemplateApi } from './apis/adboxTemplateApi';
import { adboxContentApi } from './apis/adboxContentApi';
import i18nnext from '../i18n';
import { changelogApi } from './apis/changelogApi';

export const crashReporter = (store) => (next) => (action) => {
  if (action.error && action.error.name !== 'ConditionError') {
    console.error('crashReporter:', action);
    let errorMessage = action.payload?.data?.message ? action.payload.data.message : action.error.message;
    if (i18nnext.exists(errorMessage)) {
      errorMessage = i18nnext.t(errorMessage);
    }
    store.dispatch(addToast({ message: errorMessage, title: i18nnext.t('error.error'), color: 'danger' }));
  }

  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    /*Raven.captureException(err, {
      extra: {
        action,
        state: store.getState()
      }
    })*/
    throw err;
  }
};

const getEntries = (value: any) => {
  const plain = instanceToPlain(value);
  return Object.entries(plain);
};

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable: () => true,
  getEntries,
});

const store = configureStore({
  reducer: {
    credentials: credentialsSlice,
    ui: uiSlice,
    table: tableSlice,
    organisation: organisationSlice,
    [authApi.reducerPath]: authApi.reducer,
    [caslApi.reducerPath]: caslApi.reducer,
    [editorApi.reducerPath]: editorApi.reducer,
    [hubApi.reducerPath]: hubApi.reducer,
    [organisationApi.reducerPath]: organisationApi.reducer,
    [documentTemplateApi.reducerPath]: documentTemplateApi.reducer,
    [documentLayoutApi.reducerPath]: documentLayoutApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [tableApi.reducerPath]: tableApi.reducer,
    [statsApi.reducerPath]: statsApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
    [streamApi.reducerPath]: streamApi.reducer,
    [linkApi.reducerPath]: linkApi.reducer,
    [authorApi.reducerPath]: authorApi.reducer,
    [tagApi.reducerPath]: tagApi.reducer,
    [inputFilterApi.reducerPath]: inputFilterApi.reducer,
    [importerApi.reducerPath]: importerApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [adboxApi.reducerPath]: adboxApi.reducer,
    [adboxTemplateApi.reducerPath]: adboxTemplateApi.reducer,
    [adboxContentApi.reducerPath]: adboxContentApi.reducer,
    [changelogApi.reducerPath]: changelogApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      serializableMiddleware,
      crashReporter,
      save({ states: ['credentials'] }),
      authApi.middleware,
      caslApi.middleware,
      editorApi.middleware,
      hubApi.middleware,
      organisationApi.middleware,
      documentTemplateApi.middleware,
      documentLayoutApi.middleware,
      userApi.middleware,
      tableApi.middleware,
      statsApi.middleware,
      mediaApi.middleware,
      streamApi.middleware,
      linkApi.middleware,
      authorApi.middleware,
      tagApi.middleware,
      inputFilterApi.middleware,
      importerApi.middleware,
      configApi.middleware,
      adboxApi.middleware,
      adboxTemplateApi.middleware,
      adboxContentApi.middleware,
      changelogApi.middleware,
    ),
  preloadedState: load({ states: ['credentials'] }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

setupListeners(store.dispatch);

export default store;
