import { Expose, Type } from 'class-transformer';
import { OrganisationEntity } from '../organisation';

export class LinkEntity {
  @Expose()
  uuid?: string;

  @Expose()
  url: string;

  @Expose()
  title?: string;

  @Expose()
  customUrl?: string;

  @Expose()
  @Type(() => OrganisationEntity)
  organisation?: OrganisationEntity;

  @Expose()
  createdAt?: Date;

  @Expose()
  updatedAt?: Date;
}
