import { ConfigDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { ConfigModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/config',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    getConfig: builder.query<ConfigModel, string>({ query: (uuid) => uuid, keepUnusedDataFor: 0 }),
    updateConfig: builder.query<ConfigModel, { uuid: string; dto: ConfigDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: uuid, body: dto }),
    }),
    createConfig: builder.query<ConfigModel, ConfigDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
    }),
    deleteConfig: builder.query<ConfigModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: uuid }),
    }),
  }),
});

export const { useGetConfigQuery, useLazyUpdateConfigQuery, useLazyCreateConfigQuery, useLazyDeleteConfigQuery } = configApi;
