import { Expose, Type } from 'class-transformer';

export class InputFilterEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  className: string;

  @Expose()
  parameters: string;
}
