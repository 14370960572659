import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps {
  title?: string;
  message: string;
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  confirmButtonLabel?: string;
  dismissButtonLabel?: string;
}

const AppConfirmDialog = (props: ConfirmDialogProps) => {
  const { title, message, onConfirm, onDismiss, visible, confirmButtonLabel, dismissButtonLabel } = props;
  const { t } = useTranslation();

  // https://coreui.io/react/docs/4.0/components/modal/

  return (
    <CModal alignment="center" visible={visible} onClose={() => onDismiss()}>
      {!isEmpty(title) ? (
        <CModalHeader>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
      ) : (
        <></>
      )}
      <CModalBody dangerouslySetInnerHTML={{ __html: message }}></CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => onDismiss()}>
          {dismissButtonLabel ?? t('cancel')}
        </CButton>
        <CButton
          color="primary"
          onClick={() => {
            onConfirm();
          }}
        >
          {confirmButtonLabel ?? t('ok')}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
export default AppConfirmDialog;
