import { Expose, Type } from 'class-transformer';
import { AdboxTemplateSlotEntity } from './adboxtemplateslot.entity';
import { DocumentTemplateEntity } from '../documenttemplate';
import type { AdboxEntity as AdboxEntityType } from './adbox.entity';
import { AdboxEntity } from './adbox.entity';

export class AdboxImporterOptionsEntity {
  @Expose()
  url?: string;

  @Expose()
  @Type(() => DocumentTemplateEntity)
  documentTemplate?: DocumentTemplateEntity;
}

export class AdboxImporterSlotMappingEntity {
  @Expose()
  @Type(() => AdboxTemplateSlotEntity)
  slot: AdboxTemplateSlotEntity;

  @Expose()
  importerField: string;
}

export class AdboxImporterEntity {
  @Expose()
  uuid?: string;

  @Expose()
  type: 'newscube' | 'rss' | 'youtube';

  @Expose()
  @Type(() => AdboxEntity)
  adbox?: AdboxEntityType;

  @Expose()
  @Type(() => AdboxImporterOptionsEntity)
  options: AdboxImporterOptionsEntity;

  @Expose()
  @Type(() => AdboxImporterSlotMappingEntity)
  slotMappings: AdboxImporterSlotMappingEntity[];
}
