import { CButton, CFormSelect, CModal, CModalBody, CModalFooter } from '@coreui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AppLanguageProps {
  onChange: (lng: string) => void;
  onDismiss: () => void;
  isOpen: boolean;
  currentLng: string;
}

const AppLanguageModal = (props: AppLanguageProps) => {
  const { onChange, onDismiss, isOpen, currentLng } = props;
  const [lng, setLng] = useState(currentLng);
  const { t } = useTranslation();

  return (
    <CModal visible={isOpen}>
      <CModalBody>
        <CFormSelect value={lng} onChange={(e) => setLng(e.currentTarget.value)}>
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </CFormSelect>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => onDismiss()}>
          {t('cancel')}
        </CButton>
        <CButton
          color="primary"
          onClick={() => {
            onChange(lng);
          }}
        >
          {t('ok')}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
export default AppLanguageModal;
