import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import { AppAbility, PolicyAction, PolicySubject } from '@nccore/shared';
import { each, isEmpty } from 'lodash';
import { UserModel } from '../models';

export const AbilityContext = createContext(defineAbilityFor(null));
export const Can = createContextualCan(AbilityContext.Consumer);

export default function defineAbilityFor(user: UserModel) {
  const { can, cannot, build } = new AbilityBuilder<AppAbility>(Ability as AbilityClass<AppAbility>);

  if (isEmpty(user)) {
    return build();
  }

  if (user.isAdmin) {
    each(PolicySubject, (subject) => {
      can(PolicyAction.Manage, subject);
      can(PolicyAction.Read, subject);
      can(PolicyAction.Create, subject);
      can(PolicyAction.Update, subject);
      can(PolicyAction.Delete, subject);
    });
    can(PolicyAction.Manage, PolicySubject.All); // read-write access to everything
  } else {
    each(user.abilities, (ability) => {
      const action = PolicyAction[ability.action];
      const subject = PolicySubject[ability.subject];
      can(action, subject);
    });
    if (!isEmpty(user.role)) {
      each(user.role.abilities, (ability) => {
        const action = PolicyAction[ability.action];
        const subject = PolicySubject[ability.subject];
        can(action, subject);
      });
    }
  }

  return build();
}
