import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import Dropzone from 'react-dropzone';
import { CCol, CContainer, CListGroup, CListGroupItem, CProgress, CProgressBar, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCloudUpload } from '@coreui/icons';
import { post } from '../../api';
import AlertContext from '../AppAlertProvider';
import { isEmpty, isNull } from 'lodash';
import { useTranslation } from 'react-i18next';

// https://github.com/react-dropzone/react-dropzone

const chunkSize = 5 * 1024 * 1024;

export interface MediaUploadComponentHandle {
  uploadFiles: () => void;
}

interface MediaUploadComponentProps {
  onUploadSuccess: () => void;
  onUploadError: (error: any) => void;
}

const MediaUploadComponent = forwardRef((props: MediaUploadComponentProps, ref) => {
  const { onUploadSuccess, onUploadError } = props;
  const alert = useContext(AlertContext);
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [currentUploadUuid, setCurrentUploadUuid] = useState(null);

  const { t } = useTranslation('media');
  const { t: tGeneral } = useTranslation();

  useImperativeHandle(ref, () => ({
    uploadFiles(): void {
      if (!files.length) {
        alert.showError(tGeneral('none-selected', { type: t('media-plural') }));
        onUploadError('No files selected');
        return;
      }
      setCurrentFileIndex(0);
    },
  }));

  const readAndUploadFileChunk = () => {
    const reader = new FileReader();
    const file = files[currentFileIndex];
    if (isEmpty(file)) {
      return;
    }

    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;

    const blob = file.slice(from, to);
    reader.onload = (e) => {
      uploadChunk(e);
    };
    reader.readAsDataURL(blob);
  };

  const uploadChunk = (e) => {
    const file = files[currentFileIndex];
    const data = e.target.result;
    const totalChunks = Math.ceil(file.size / chunkSize);

    const params = new URLSearchParams();
    if (isEmpty(currentUploadUuid)) {
      params.set('name', file.name);
      params.set('size', file.size);
      params.set('totalChunks', `${totalChunks}`);
    }
    params.set('currentChunkIndex', `${currentChunkIndex}`);

    const uploadPath = !isEmpty(currentUploadUuid) ? `/media/upload/${currentUploadUuid}` : '/media/upload';

    post(`${uploadPath}?${params.toString()}`, { data: data }).then((res) => {
      const mediaUuid = res.data?.uuid;
      setCurrentUploadUuid(mediaUuid);

      if (currentChunkIndex !== totalChunks - 1) {
        setCurrentChunkIndex(currentChunkIndex + 1);
      } else {
        uploadNextFile();
      }
    });
  };

  const uploadNextFile = () => {
    setCurrentChunkIndex(0);
    setCurrentUploadUuid(null);
    if (currentFileIndex < files.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
    } else {
      setCurrentFileIndex(null);
      onUploadSuccess();
    }
  };

  useEffect(() => {
    if (currentChunkIndex !== 0) {
      readAndUploadFileChunk();
    }
  }, [currentChunkIndex]);

  useEffect(() => {
    if (!isNull(currentFileIndex)) {
      readAndUploadFileChunk();
    } else {
      setCurrentChunkIndex(0);
      setCurrentUploadUuid(null);
    }
  }, [currentFileIndex]);

  const onDrop = (files) => {
    setFiles(files);
  };

  const getFileProgress = (index) => {
    if (index < currentFileIndex) {
      return 100;
    }
    if (index === currentFileIndex) {
      const file = files[currentFileIndex];
      const totalChunks = Math.ceil(file.size / chunkSize);

      return (100 / totalChunks) * currentChunkIndex;
    }
    return 0;
  };

  return (
    <div>
      <CContainer>
        <CRow>
          <CCol>
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                      <CIcon icon={cilCloudUpload} height={100} />
                      <br />
                      {t('drag-n-drop-or-click')}
                      <div style={{ display: files.length ? 'block' : 'none' }} className="mt-4">
                        {tGeneral('selected-type', { type: t('files') })}:
                        <CListGroup>
                          {files.map((file, key) => {
                            return (
                              <CListGroupItem key={key}>
                                {file.name}
                                <CProgress>
                                  <CProgressBar value={getFileProgress(key)} />
                                </CProgress>
                              </CListGroupItem>
                            );
                          })}
                        </CListGroup>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
});

export default MediaUploadComponent;
