import { Expose } from 'class-transformer';

export class AdboxTemplateSlotEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  internalTitle?: string;

  @Expose()
  contentType: string;

  @Expose()
  required: boolean;

  @Expose()
  hidden: boolean;

  @Expose()
  defaultValue: string;

  @Expose()
  customizable: boolean;

  @Expose()
  maxLength: number;

  @Expose()
  endCharacters: string;

  @Expose()
  position: number;
}
