import { Expose, Type } from 'class-transformer';

export class ChangelogEntity {
  @Expose()
  uuid?: string;

  @Expose()
  date: Date;

  @Expose()
  type: string;

  @Expose()
  content: string;

  @Expose()
  sentNewsletter?: boolean;

  @Expose()
  createdAt?: Date;

  @Expose()
  updatedAt?: Date;

  @Expose()
  deletedAt?: Date;
}
