import { Expose } from 'class-transformer';
import { DocumentEntity } from '../document';
import { DocumentLayoutEntity } from '../documentlayout';
import { DocumentTemplateEntity } from '../documenttemplate';
import { HubEntity } from '../hub';
import { InputFilterEntity } from '../inputfilter';
import { MediaEntity } from '../media';
import { OrganisationEntity } from '../organisation';
import { StreamEntity } from '../stream';
import { UserEntity } from '../user';

type TableDataEntitys =
  | DocumentEntity[]
  | DocumentTemplateEntity[]
  | HubEntity[]
  | MediaEntity[]
  | OrganisationEntity[]
  | StreamEntity[]
  | UserEntity[]
  | DocumentLayoutEntity[]
  | InputFilterEntity[];

export class TableResultEntity {
  @Expose()
  data: TableDataEntitys;

  @Expose()
  count: number;
}
