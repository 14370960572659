import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import QuickForm, { QuickFormField, QuickFormFields } from './QuickForm';
import AppButton from './AppButton';
import { CAlert, CButton, CNav, CNavItem } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';

interface AppWizardStep {
  formFields: QuickFormFields;
  title?: string;
}

interface AppWizardProps {
  steps: AppWizardStep[];
  onChange: (data: any, isValid?: boolean, changedField?: QuickFormField) => void;
  onSubmit: () => void;
  data?: any;
  hideNavbar?: boolean;
  enableNavbarStepchange?: boolean;
  isLoading?: boolean;
}

export interface AppWizardHandler {
  isValid: () => boolean;
}

const AppWizard = forwardRef<AppWizardHandler, AppWizardProps>((props: AppWizardProps, ref) => {
  const { steps, data, onChange, onSubmit, hideNavbar, enableNavbarStepchange, isLoading } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [validSteps, setValidSteps] = useState<boolean[]>([]);
  const [formData, setFormData] = useState<any>({});
  const formRefs = useRef([]);

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    isValid() {
      return isWizardValid();
    },
  }));

  useEffect(() => {
    if (!isEmpty(data)) {
      setFormData(data);
    }
  }, [data]);

  useEffect(() => {
    const currentValidSteps = [...validSteps];
    if (isEmpty(steps[currentStep]?.formFields)) {
      currentValidSteps[currentStep] = true;
    } else {
      currentValidSteps[currentStep] = formRefs.current[currentStep]?.isValid();
    }
    setValidSteps(currentValidSteps);
  }, [formRefs.current, currentStep, formData]);

  const isWizardValid = () => {
    for (let i = 0; i < steps.length; i++) {
      if (!validSteps[i]) {
        return false;
      }
    }
    return true;
  };

  const changeStep = (step: number) => {
    if (step >= 0 && step < steps.length && validSteps[currentStep]) {
      setCurrentStep(step);
    }
  };

  // render the current step
  const renderStep = () => {
    const step = steps[currentStep];

    if (isEmpty(step.formFields)) {
      return <CAlert color="warning">Keine Felder verfügbar</CAlert>;
    }

    return (
      <QuickForm
        fields={step.formFields}
        name={`wizard_form_${currentStep}`}
        obj={formData}
        onChange={(obj, isValid, formField) => {
          setFormData(obj);
          onChange(obj, isValid, formField);

          const currentValidSteps = [...validSteps];
          currentValidSteps[currentStep] = isValid;
          setValidSteps(currentValidSteps);
        }}
        ref={(ref) => {
          if (isNil(formRefs[currentStep])) {
            formRefs.current[currentStep] = ref;
          }
        }}
      />
    );
  };

  // render navigation
  const renderNavigation = () => {
    return (
      <CNav className="justify-content-center mb-3">
        {steps.map((step, key) => {
          return (
            <CNavItem key={key} className="ml-3">
              <CButton
                color={key == currentStep ? 'primary' : 'secondary'}
                className="mr-1"
                onClick={() => {
                  if (enableNavbarStepchange) changeStep(key);
                }}
                disabled={!enableNavbarStepchange || currentStep == key || !validSteps[currentStep]}
                shape="rounded-pill"
              >
                {step.title ?? `${t('step')} ${key + 1}`}
              </CButton>
              {key < steps.length - 1 && (
                <span className="ml-2" style={{ color: 'grey' }}>
                  {'-'}
                </span>
              )}
            </CNavItem>
          );
        })}
      </CNav>
    );
  };

  // render the buttons
  const renderButtons = () => {
    return (
      <>
        <AppButton title={t('back')} onClick={() => changeStep(currentStep - 1)} disabled={currentStep == 0 || !validSteps[currentStep] || isLoading} />
        <AppButton
          isLoading={isLoading}
          className="ml-3"
          title={currentStep == steps.length - 1 ? t('complete') : t('next')}
          onClick={() => {
            if (currentStep == steps.length - 1) {
              onSubmit();
            } else {
              changeStep(currentStep + 1);
            }
          }}
          color={validSteps[currentStep] ? 'primary' : 'secondary'}
          disabled={!validSteps[currentStep]}
        />
      </>
    );
  };

  return (
    <div>
      {hideNavbar ?? <div>{renderNavigation()}</div>}
      <div>{renderStep()}</div>
      <div style={{ float: 'right' }}>{renderButtons()}</div>
    </div>
  );
});

export default AppWizard;
