import { Expose, Transform } from 'class-transformer';

export class ConfigEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  data: string;

  @Expose()
  createdAt?: Date;

  @Expose()
  updatedAt?: Date;

  @Expose()
  deletedAt?: Date;
}
