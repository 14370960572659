import { ImporterDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { ImporterModel } from '../../models';

export const importerApi = createApi({
  reducerPath: 'importerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/importer',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    createImporter: builder.query<ImporterModel, ImporterDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
      keepUnusedDataFor: 0,
    }),
    getImporter: builder.query<ImporterModel, string>({
      query: (uuid) => ({ url: uuid }),
      keepUnusedDataFor: 0,
    }),
    updateImporter: builder.query<ImporterModel, { uuid: string; dto: ImporterDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: uuid, body: dto }),
    }),
    deleteImporter: builder.query<ImporterModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: uuid }),
    }),
    startImporter: builder.query<ImporterModel, string>({
      query: (uuid) => ({ method: 'POST', url: `${uuid}/start` }),
    }),
  }),
});

export const { useGetImporterQuery, useLazyCreateImporterQuery, useLazyUpdateImporterQuery, useLazyDeleteImporterQuery, useLazyStartImporterQuery } =
  importerApi;
