import { Expose, Type } from 'class-transformer';
import { AdboxTemplateSlotEntity } from './adboxtemplateslot.entity';
import type { AdboxTemplateSlotEntity as AdboxTemplateSlotEntityType } from './adboxtemplateslot.entity';
import { AdboxEntity } from './adbox.entity';
import type { AdboxEntity as AdboxEntityType } from './adbox.entity';

export class AdboxLinkEntity {
  @Expose()
  uuid?: string;

  @Expose()
  @Type(() => AdboxEntity)
  adbox: AdboxEntityType;

  @Expose()
  @Type(() => AdboxTemplateSlotEntity)
  slot: AdboxTemplateSlotEntityType;

  @Expose()
  url: string;
}
