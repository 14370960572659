import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { AuthorEntity, ExternalAuthorEntity } from '../author';
import { DocumentTemplateEntity, ExternalDocumentTemplateEntity } from '../documenttemplate';
import { ExternalMediaPublishedEntity, MediaEntity } from '../media';
import { ExternalOrganisationEntity, OrganisationEntity } from '../organisation';
import { StreamEntity } from '../stream';
import { TagEntity } from '../tag';
import { TrackingCodeEntity } from '../trackingcode';
import { DocumentContentEntity } from './document-content.entity';
import { DocumentHistoryEntity } from './document-history.entity';
import type { DocumentHistoryEntity as DocumentHistoryEntityType } from './document-history.entity';
import { DocumentLinkEntity, ExternalDocumentLinkPublishedEntity } from './document-link.entity';
import { findLast, isNil, last } from 'lodash';
import { ExternalDocumentLayoutEntity } from '../documentlayout';

export class DocumentEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  @Type(() => OrganisationEntity)
  organisation: OrganisationEntity;

  @Expose()
  @Type(() => AuthorEntity)
  author: AuthorEntity;

  @Expose()
  @Type(() => DocumentHistoryEntity)
  documentHistory?: Array<DocumentHistoryEntityType>;

  @Expose()
  @Type(() => DocumentHistoryEntity)
  @Transform(({ obj }) => {
    return findLast(obj.documentHistory, (history) => !isNil(history.publishedAt));
  })
  publishedHistory?: DocumentHistoryEntityType;

  @Expose()
  @Type(() => DocumentHistoryEntity)
  @Transform(({ obj }) => {
    return last(obj.documentHistory);
  })
  lastHistory?: DocumentHistoryEntityType;

  @Expose()
  @Type(() => DocumentTemplateEntity)
  documentTemplate: DocumentTemplateEntity;

  @Expose()
  @Type(() => DocumentContentEntity)
  documentContent: Array<DocumentContentEntity>;

  @Expose()
  @Type(() => DocumentLinkEntity)
  documentLinks?: Array<DocumentLinkEntity>;

  @Expose()
  @Type(() => TagEntity)
  documentTags?: Array<TagEntity>;

  @Expose()
  documentCustomTags?: Array<string>;

  @Expose()
  @Type(() => TrackingCodeEntity)
  trackingCodes?: TrackingCodeEntity[];

  @Expose()
  status: string;

  @Expose()
  importerContent?: string;

  @Expose()
  createdAt?: Date | string;

  @Expose()
  updatedAt?: Date | string;

  @Expose()
  deletedAt?: Date | string;

  @Expose()
  publishedAt?: Date | string;
}

export class DocumentPublishedEntity extends DocumentEntity {
  @Expose()
  @Type(() => StreamEntity)
  streams: StreamEntity[];

  @Expose()
  adbox: boolean;

  @Expose()
  publishedAt: Date | string;
}

export class ExternalSystemTagEntity {
  @Expose()
  uuid: string;

  @Expose()
  title: string;

  @Expose()
  isin?: string;

  @Expose()
  wkn?: string;
}

export class ExternalDocumentPublishedEntity extends DocumentPublishedEntity {
  @Expose()
  contentType?: string;

  @Expose()
  body?: string;

  @Expose()
  subtitle?: string;

  @Expose()
  teaser?: string;

  @Expose()
  @Type(() => ExternalMediaPublishedEntity)
  attachments?: Array<ExternalMediaPublishedEntity>;

  @Exclude()
  notified?: boolean;

  @Exclude()
  documentContent: DocumentContentEntity[];

  @Exclude()
  streams: StreamEntity[];

  @Exclude()
  importerContent?: string;

  @Exclude()
  documentHistory?: Array<DocumentHistoryEntityType>;

  @Exclude()
  trackingCodes?: TrackingCodeEntity[];

  @Expose()
  @Type(() => ExternalOrganisationEntity)
  organisation: ExternalOrganisationEntity;

  @Expose()
  @Type(() => ExternalDocumentTemplateEntity)
  documentTemplate: ExternalDocumentTemplateEntity;

  @Expose()
  @Type(() => ExternalMediaPublishedEntity)
  previewImages?: ExternalMediaPublishedEntity[];

  @Expose()
  @Type(() => ExternalDocumentLinkPublishedEntity)
  documentLinks?: ExternalDocumentLinkPublishedEntity[];

  @Expose()
  @Type(() => ExternalAuthorEntity)
  author: ExternalAuthorEntity;

  @Expose()
  @Type(() => ExternalDocumentLayoutEntity)
  documentLayout?: ExternalDocumentLayoutEntity;

  @Expose()
  @Type(() => ExternalSystemTagEntity)
  systemTags?: ExternalSystemTagEntity[];
}

export class ExternalDocumentDeletedEntity extends ExternalDocumentPublishedEntity {
  @Exclude()
  systemTags?: ExternalSystemTagEntity[];

  @Exclude()
  documentCustomTags?: string[];

  @Exclude()
  documentTags?: TagEntity[];

  @Exclude()
  documentLinks?: ExternalDocumentLinkPublishedEntity[];

  @Exclude()
  documentTemplate: ExternalDocumentTemplateEntity;

  @Exclude()
  title: string;
}
