import moment from 'moment';
import 'flatpickr/dist/themes/material_orange.css';
import Flatpickr from 'react-flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';
import { CFormInput, CInputGroup, CInputGroupText } from '@coreui/react';
import { cilCalendar } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useTranslation } from 'react-i18next';

interface AppDateTimeSelectorProps {
  enableTime?: boolean;
  value?: string;
  disableIcon?: boolean;
  onChange: (value: string, date: Date) => void;
}

const CustomDateTimeInput = ({ disableIcon, value, defaultValue, inputRef, onBlur, ...props }) => {
  if (disableIcon) {
    return <CFormInput {...props} defaultValue={defaultValue} ref={inputRef} onBlur={onBlur} />;
  }

  return (
    <CInputGroup>
      <CInputGroupText>
        <CIcon icon={cilCalendar} />
      </CInputGroupText>
      <CFormInput {...props} defaultValue={defaultValue} ref={inputRef} onBlur={onBlur} />
    </CInputGroup>
  );
};

const AppDateTimeSelector = (props: AppDateTimeSelectorProps) => {
  const { enableTime, value, onChange, disableIcon } = props;
  const { t } = useTranslation();

  const dateFormat = enableTime ? t('date-hour-format') : t('date-format');

  return (
    <Flatpickr
      value={value}
      onChange={(date) => {
        const d = moment(date[0]);
        const formatedDate = d.format(dateFormat);
        onChange(formatedDate, d.toDate());
      }}
      options={{
        allowInput: true,
        enableTime,
        time_24hr: true,
        dateFormat: enableTime ? t('date-hour-format-flatpickr') : t('date-format-flatpickr'),
        locale: German,
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <CustomDateTimeInput
            disableIcon={disableIcon}
            value={value}
            defaultValue={defaultValue}
            inputRef={ref}
            onBlur={(e) => {
              const d = moment(e.target.value, dateFormat);
              if (d.isValid()) {
                const formatedDate = d.format(dateFormat);
                onChange(formatedDate, d.toDate());
              }
            }}
          />
        );
      }}
    />
  );
};

export default AppDateTimeSelector;
