import { cilX } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CImage } from '@coreui/react';
import { isEmpty, isString } from 'lodash';
import { useEffect, useState } from 'react';
import { environment } from '../../environments/environment';
import { MediaModel } from '../../models';
import { useLazyGetMediaQuery } from '../../store/apis/mediaApi';
import MediaSelectModal from './MediaSelectModal';
import { useTranslation } from 'react-i18next';

interface MediaSelectComponentProps {
  value: MediaModel | string;
  onChange: (media: MediaModel) => void;
  required?: boolean;
  invalid?: boolean;
}

const MediaSelectComponent = (props: MediaSelectComponentProps) => {
  const { value, onChange } = props;
  const [mediaModalVisible, setMediaModalVisible] = useState(false);
  const [mediaEntity, setMediaEntity] = useState(null);
  const [triggerGet, { data: mediaData, isSuccess }] = useLazyGetMediaQuery();

  const { t } = useTranslation('media');
  const { t: tGeneral } = useTranslation();

  useEffect(() => {
    if (isEmpty(value)) {
      return;
    }

    if (!isString(value)) {
      setMediaEntity(value);
    } else {
      triggerGet(value);
    }
  }, [value]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setMediaEntity(mediaData);
  }, [mediaData]);

  const onSelect = (media) => {
    setMediaModalVisible(false);
    setMediaEntity(media);
    onChange(media);
  };

  return (
    <div>
      {!isEmpty(mediaEntity) ? (
        <CImage src={`${environment.cdnHost}/media/${mediaEntity.uuid}`} style={{ maxHeight: '100px', display: 'inline-block' }} />
      ) : (
        <></>
      )}
      <CButton size="sm" onClick={() => setMediaModalVisible(true)} style={{ marginLeft: isEmpty(mediaEntity) ? 0 : '1em' }} color="secondary">
        {tGeneral('select.message', { type: t('media') })}
      </CButton>
      {!isEmpty(mediaEntity) ? (
        <>
          <CButton size="sm" className="ml-1" onClick={() => onSelect(null)} color="danger">
            <CIcon icon={cilX} />
          </CButton>
        </>
      ) : (
        <></>
      )}
      <MediaSelectModal visible={mediaModalVisible} onDismiss={() => setMediaModalVisible(false)} onSelect={onSelect} />
    </div>
  );
};

export default MediaSelectComponent;
