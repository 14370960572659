import { Expose } from 'class-transformer';
import { IsOptional } from 'class-validator';

export class ImporterDto {
  @Expose()
  @IsOptional()
  uuid?: string;
  @Expose()
  title: string;
  @Expose()
  className: string;
  @Expose()
  @IsOptional()
  parameters?: string;
  @Expose()
  organisationUuid: string;
  @Expose()
  documentTemplateUuid: string;
  @Expose()
  streamUuids: string[];
  @Expose()
  userUuid: string;
  @Expose()
  interval?: number;
  @Expose()
  webhook?: boolean;
  @Expose()
  isActive: boolean;
}
