import { Expose } from 'class-transformer';

export class TagContentEntity {
  @Expose()
  uuid?: string;

  @Expose()
  content: string;

  @Expose()
  type: string;
}
