import { Expose, Type } from 'class-transformer';
import { DocumentTemplateSlotEntity } from '../documenttemplate';

export class DocumentContentEntity {
  @Expose()
  content: string;

  @Expose()
  @Type(() => DocumentTemplateSlotEntity)
  documentTemplateSlot: DocumentTemplateSlotEntity;
}
