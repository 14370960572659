import { Expose, Type } from 'class-transformer';
import { UserEntity } from '../user';

export class MailEntity {
  @Type(() => UserEntity)
  user: UserEntity;

  @Expose()
  subject: string;

  @Expose()
  template: string;

  @Expose()
  data: any;

  @Expose()
  response: any;

  @Expose()
  date: Date;
}
