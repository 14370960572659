import { Expose, Type } from 'class-transformer';
import { AbilityEntity } from './casl.entity';

export class RoleEntity {
  @Expose()
  uuid?: string;
  @Expose()
  title: string;
  @Expose()
  @Type(() => AbilityEntity)
  abilities: AbilityEntity[];
}
