import { createContext } from 'react';
import { connect } from 'socket.io-client';
import { environment } from '../environments/environment';
import { BaseEvent } from '@nccore/shared';
import { isEmpty } from 'lodash';

export const socket = connect(environment.socketHost, { transports: ['websocket'] });

export const subscribe = <EventType extends BaseEvent>(event: EventType, uuid: string, callback: (event: BaseEvent) => void) => {
  if (!isEmpty(uuid) && !isEmpty(event?.name)) {
    socket.emit('subscribe', { event: event.name, uuid });
    socket.on(event.name, (event: BaseEvent) => {
      callback(event);
    });
  }
};

export const unsubscribe = <EventType extends BaseEvent>(event: EventType, uuid: string) => {
  if (!isEmpty(uuid) && !isEmpty(event?.name)) {
    socket.emit('unsubscribe', { event: event.name, uuid });
    socket.off(event.name);
  }
};

export const SocketContext = createContext({ socket, subscribe, unsubscribe });
