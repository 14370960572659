import PropTypes from 'prop-types';
import { cilPen, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';

const AppTableActionButtons = (props) => {
  const { setModalData, setModalVisible, onDelete, data } = props;

  return (
    <div>
      <CButton
        className="mr-4"
        color="warning"
        variant="ghost"
        shape="rounded-pill"
        onClick={() => {
          setModalData(data);
          setModalVisible(true);
        }}
      >
        <CIcon icon={cilPen}></CIcon>
      </CButton>
      <CButton color="danger" variant="ghost" shape="rounded-pill" onClick={() => onDelete(data)}>
        <CIcon icon={cilTrash}></CIcon>
      </CButton>
    </div>
  );
};

AppTableActionButtons.propTypes = {
  setModalData: PropTypes.func.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default AppTableActionButtons;
