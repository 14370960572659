import { Ability } from '@casl/ability';

export enum PolicyAction {
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export enum PolicySubject {
  Dashboard = 'Dashboard',
  User = 'User',
  Media = 'Media',
  Document = 'Document',
  DocumentTemplate = 'DocumentTemplate',
  DocumentLayout = 'DocumentLayout',
  Link = 'Link',
  Organisation = 'Organisation',
  Stream = 'Stream',
  Hub = 'Hub',
  Stats = 'Stats',
  StatsHub = 'StatsHub',
  Role = 'Role',
  Author = 'Author',
  Tag = 'Tag',
  InputFilter = 'InputFilter',
  Importer = 'Importer',
  Config = 'Config',
  Adbox = 'Adbox',
  AdboxTemplate = 'AdboxTemplate',
  Changelog = 'Changelog',
  All = 'all',
}

export enum PolicySubjectUser {
  Dashboard = 'Dashboard',
  User = 'User',
  Media = 'Media',
  Document = 'Document',
  Link = 'Link',
  Stats = 'Stats',
  Author = 'Author',
}

export type AppAbility = Ability<[PolicyAction, PolicySubject]>;
