import { Expose, Type } from 'class-transformer';
import { OrganisationEntity } from '../organisation';
import { UserEntity } from '../user';
import { AdboxTemplateSlotEntity } from './adboxtemplateslot.entity';
import { MediaEntity } from '../media';

export class AdboxTemplateEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  description?: string;

  @Expose()
  @Type(() => MediaEntity)
  previewImage?: MediaEntity;

  @Expose()
  html: string;

  @Expose()
  @Type(() => AdboxTemplateSlotEntity)
  slots: AdboxTemplateSlotEntity[];

  @Expose()
  @Type(() => UserEntity)
  user: UserEntity;

  @Expose()
  @Type(() => OrganisationEntity)
  organisation?: OrganisationEntity;

  @Expose()
  createdAt?: Date | string;

  @Expose()
  updatedAt?: Date | string;

  @Expose()
  deletedAt?: Date | string;
}
