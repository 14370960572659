import { Expose } from 'class-transformer';
import { IsOptional } from 'class-validator';

export class TagContentDto {
  @Expose()
  @IsOptional()
  uuid?: string;
  @Expose()
  content: string;
  @Expose()
  type: string;
}
