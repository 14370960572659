import { UserEntity } from '../entities';

export abstract class BaseEvent {
  [x: string]: any;
}

export abstract class BaseCRUDEvent<T> implements BaseEvent {
  entity: T;
  producer: UserEntity;
  date: Date;

  constructor(entity: T, producer: UserEntity = null) {
    this.entity = entity;
    this.producer = producer;
    this.date = new Date();
  }
}
