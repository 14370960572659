import { UpdateProfileDto, UserDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { UserModel } from '../../models';
import { isEmpty } from 'lodash';
import { setUser } from '../slices/credentialsSlice';

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.apiHost}/user`,
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    createUser: builder.query<UserModel, UserDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
      keepUnusedDataFor: 0,
    }),
    getUser: builder.query<UserModel, string>({
      query: (uuid) => ({ url: `${uuid}` }),
      keepUnusedDataFor: 0,
    }),
    updateUser: builder.query<UserModel, { uuid: string; dto: UserDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: `${uuid}`, body: dto }),
    }),
    updateProfile: builder.query<UserModel, { dto: UpdateProfileDto }>({
      query: ({ dto }) => ({ method: 'PUT', url: `profile`, body: dto }),
    }),
    deleteUser: builder.query<UserModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: `${uuid}` }),
    }),
    requestPasswordReset: builder.query<UserModel, string>({
      query: (uuid) => ({ method: 'GET', url: `request-password-reset/${uuid}` }),
    }),
    setPasswordMail: builder.query<UserModel, string>({
      query: (uuid) => ({ method: 'GET', url: `set-password-mail/${uuid}` }),
    }),
    startedTour: builder.query<UserModel, void>({
      query: () => ({ method: 'POST', url: 'startedTour' }),
      keepUnusedDataFor: 0,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!isEmpty(data)) {
            dispatch(setUser(data));
          }
        } catch (error) {
          console.error('startedTour ERROR:', error);
        }
      },
    }),
    shownChangelog: builder.query<UserModel, void>({
      query: () => ({ method: 'POST', url: `shownChangelog` }),
      keepUnusedDataFor: 0,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!isEmpty(data)) {
            dispatch(setUser(data));
          }
        } catch (error) {
          console.error('shownChangelog ERROR:', error);
        }
      },
    }),
  }),
});

export const {
  useLazyCreateUserQuery,
  useGetUserQuery,
  useLazyUpdateUserQuery,
  useLazyDeleteUserQuery,
  useLazyRequestPasswordResetQuery,
  useLazySetPasswordMailQuery,
  useLazyStartedTourQuery,
  useLazyUpdateProfileQuery,
  useLazyShownChangelogQuery,
} = userApi;
