import { Expose, Type } from 'class-transformer';
import { AbilityEntity, RoleEntity } from '../casl';
import { MediaEntity } from '../media';
import { OrganisationEntity } from '../organisation';
import type { MediaEntity as MediaEntityType } from '../media';
import type { OrganisationEntity as OrganisationEntityType } from '../organisation';

export class UserEntity {
  @Expose()
  uuid?: string;

  @Expose()
  email: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  photoURL?: string;

  @Expose()
  birthday?: Date;

  @Expose()
  isActive: boolean;

  @Expose()
  @Type(() => OrganisationEntity)
  organisation: OrganisationEntityType;

  @Expose()
  isAdmin: boolean;

  @Expose()
  @Type(() => AbilityEntity)
  abilities?: AbilityEntity[];

  @Expose()
  @Type(() => RoleEntity)
  role?: RoleEntity;

  @Expose()
  @Type(() => MediaEntity)
  photo: MediaEntityType;

  @Expose()
  password?: string;

  @Expose()
  tourStarted?: boolean;

  @Expose()
  latestChangelogDate?: Date;

  @Expose()
  receiveNewsletter?: boolean;

  @Expose()
  createdAt?: Date;

  @Expose()
  updatedAt?: Date;

  @Expose()
  deletedAt?: Date;
}
