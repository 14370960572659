import { CAvatar, CDropdown, CDropdownDivider, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { cilLanguage, cilLockLocked, cilSettings, cilSpeech } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useAppDispatch, useAppSelector } from '../../store';
import { isEmpty } from 'lodash';
import { logout } from '../../store/slices/credentialsSlice';
import { useNavigate } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import AppLanguageModal from '../AppLanguageModal';
import { useState } from 'react';

import userImage from '../../assets/images/user.png';

const AppHeaderDropdown = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setIsOpen } = useTour();
  const user = useAppSelector((state) => state.credentials.user);
  const [lngModalOpen, setLngModalOpen] = useState(false);
  const { t } = useTranslation('navigation');
  const { t: tGeneral, i18n } = useTranslation();

  return (
    <>
      <CDropdown variant="nav-item" placement="bottom-end">
        <CDropdownToggle className="py-0" caret={false}>
          <CAvatar src={isEmpty(user.photo) ? userImage : user.photo.s3Url} size="md" />
        </CDropdownToggle>
        <CDropdownMenu className="pt-0">
          <CDropdownHeader className="bg-light fw-semibold py-2">{t('account.title')}</CDropdownHeader>
          <CDropdownItem style={{ cursor: 'pointer' }} onClick={() => navigate('/settings/')}>
            <CIcon icon={cilSettings} className="me-2" />
            {t('account.settings')}
          </CDropdownItem>
          <CDropdownItem style={{ cursor: 'pointer' }} onClick={() => setLngModalOpen(true)}>
            <CIcon icon={cilLanguage} className="me-2" />
            {tGeneral('language')}
          </CDropdownItem>
          <CDropdownItem style={{ cursor: 'pointer' }} onClick={() => setIsOpen(true)}>
            <CIcon icon={cilSpeech} className="me-2" />
            {t('account.start-tour')}
          </CDropdownItem>
          <CDropdownDivider />
          <CDropdownItem
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(logout());
            }}
          >
            <CIcon icon={cilLockLocked} className="me-2" />
            {t('account.logout')}
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
      <AppLanguageModal
        onChange={(lng) => {
          i18n.changeLanguage(lng);
          setLngModalOpen(false);
        }}
        isOpen={lngModalOpen}
        currentLng={i18n.language}
        onDismiss={() => setLngModalOpen(false)}
      />
    </>
  );
};

export default AppHeaderDropdown;
