import { Expose, Type } from 'class-transformer';
import { HubEntity } from '../hub';
import { DocumentEntity } from '../document';
import { StreamEntity } from '../stream';

export class NotificationEntity {
  @Expose()
  uuid?: string;

  @Expose()
  @Type(() => HubEntity)
  hub: HubEntity;

  @Expose()
  @Type(() => DocumentEntity)
  document: DocumentEntity;

  @Expose()
  @Type(() => StreamEntity)
  stream: StreamEntity;

  @Expose()
  response: string;
}
