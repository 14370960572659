import { IsNotEmpty } from 'class-validator';

export class PublishDocumentDto {
  @IsNotEmpty()
  streams: string[];
  @IsNotEmpty()
  date: Date;
  adbox?: boolean;
  userUuid?: string;
}
