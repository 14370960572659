import { PolicyAction, PolicySubject } from '@nccore/shared';
import React from 'react';
import { lazyRetry } from './lazyRetry';

interface RouteInterface {
  path: string;
  translationKey: string;
  component: any;
  exact?: boolean;
  ability?: {
    subject: PolicySubject;
    action: PolicyAction;
  };
}

const Home = React.lazy(() => lazyRetry(() => import('./views/pages/Home')));
const Dashboard = React.lazy(() => lazyRetry(() => import('./views/dashboard/Dashboard')));
const EditorList = React.lazy(() => lazyRetry(() => import('./views/editor/EditorList')));
const EditorDetail = React.lazy(() => lazyRetry(() => import('./views/editor/EditorDetail')));
const LinkList = React.lazy(() => lazyRetry(() => import('./views/link/LinkList')));
const LinkDetail = React.lazy(() => lazyRetry(() => import('./views/link/LinkDetail')));
const MediaList = React.lazy(() => lazyRetry(() => import('./views/media/MediaList')));
const MediaUpload = React.lazy(() => lazyRetry(() => import('./views/media/MediaUpload')));
const StatsDashboard = React.lazy(() => lazyRetry(() => import('./views/stats/StatsDashboard')));
const StatsStreams = React.lazy(() => lazyRetry(() => import('./views/stats/StatsStreams')));
const StatsDocuments = React.lazy(() => lazyRetry(() => import('./views/stats/StatsDocuments')));
const StatsHubs = React.lazy(() => lazyRetry(() => import('./views/stats/StatsHubs')));
const StatsLinkGroups = React.lazy(() => lazyRetry(() => import('./views/stats/StatsLinkGroups')));
const StatsLinks = React.lazy(() => lazyRetry(() => import('./views/stats/StatsLinks')));
const StatsSubmitters = React.lazy(() => lazyRetry(() => import('./views/stats/StatsSubmitters')));
const Settings = React.lazy(() => lazyRetry(() => import('./views/settings/Settings')));
const OrganisationList = React.lazy(() => lazyRetry(() => import('./views/settings/organisation/OrganisationList')));
const OrganisationDetail = React.lazy(() => lazyRetry(() => import('./views/settings/organisation/OrganisationDetail')));
const UserList = React.lazy(() => lazyRetry(() => import('./views/settings/user/UserList')));
const UserDetail = React.lazy(() => lazyRetry(() => import('./views/settings/user/UserDetail')));
const HubList = React.lazy(() => lazyRetry(() => import('./views/settings/hub/HubList')));
const HubDetail = React.lazy(() => lazyRetry(() => import('./views/settings/hub/HubDetail')));
const DocumentTemplateList = React.lazy(() => lazyRetry(() => import('./views/settings/documenttemplate/DocumentTemplateList')));
const DocumentTemplateDetail = React.lazy(() => lazyRetry(() => import('./views/settings/documenttemplate/DocumentTemplateDetail')));
const DocumentLayoutList = React.lazy(() => lazyRetry(() => import('./views/settings/documentlayout/DocumentLayoutList')));
const DocumentLayoutDetail = React.lazy(() => lazyRetry(() => import('./views/settings/documentlayout/DocumentLayoutDetail')));
const RoleList = React.lazy(() => lazyRetry(() => import('./views/settings/role/RoleList')));
const RoleDetail = React.lazy(() => lazyRetry(() => import('./views/settings/role/RoleDetail')));
const TagList = React.lazy(() => lazyRetry(() => import('./views/settings/tag/TagList')));
const TagDetail = React.lazy(() => lazyRetry(() => import('./views/settings/tag/TagDetail')));
const ImporterList = React.lazy(() => lazyRetry(() => import('./views/settings/importer/ImporterList')));
const ImporterDetail = React.lazy(() => lazyRetry(() => import('./views/settings/importer/ImporterDetail')));
const AuthorList = React.lazy(() => lazyRetry(() => import('./views/settings/author/AuthorList')));
const AuthorDetail = React.lazy(() => lazyRetry(() => import('./views/settings/author/AuthorDetail')));
const InputFilterList = React.lazy(() => lazyRetry(() => import('./views/settings/inputfilter/InputFilterList')));
const InputFilterDetail = React.lazy(() => lazyRetry(() => import('./views/settings/inputfilter/InputFilterDetail')));
const ConfigList = React.lazy(() => lazyRetry(() => import('./views/settings/config/ConfigList')));
const ConfigDetail = React.lazy(() => lazyRetry(() => import('./views/settings/config/ConfigDetail')));
const AdboxList = React.lazy(() => lazyRetry(() => import('./views/adbox/AdboxList')));
const AdboxDetail = React.lazy(() => lazyRetry(() => import('./views/adbox/AdboxDetail')));
const AdboxTemplateList = React.lazy(() => lazyRetry(() => import('./views/settings/adboxtemplate/AdboxTemplateList')));
const AdboxTemplateDetail = React.lazy(() => lazyRetry(() => import('./views/settings/adboxtemplate/AdboxTemplateDetail')));
const AdboxContent = React.lazy(() => lazyRetry(() => import('./views/adbox/AdboxContent')));
const Changelog = React.lazy(() => lazyRetry(() => import('./views/settings/Changelog')));

const routes: RouteInterface[] = [
  {
    path: '/',
    translationKey: 'home',
    // component: () => <Navigate to={'/dashboard'} />,
    component: Home,
  },
  {
    path: '/dashboard',
    translationKey: 'dashboard',
    component: Dashboard,
    ability: { subject: PolicySubject.Dashboard, action: PolicyAction.Read },
  },
  {
    path: '/editor',
    translationKey: 'editor',
    component: EditorList,
    ability: { subject: PolicySubject.Document, action: PolicyAction.Read },
  },
  {
    path: '/editor/:uuid',
    translationKey: 'detail',
    component: EditorDetail,
    ability: { subject: PolicySubject.Document, action: PolicyAction.Read },
  },
  {
    path: '/links',
    translationKey: 'links',
    component: LinkList,
    ability: { subject: PolicySubject.Link, action: PolicyAction.Read },
  },
  {
    path: '/links/:uuid',
    translationKey: 'detail',
    component: LinkDetail,
    ability: { subject: PolicySubject.Link, action: PolicyAction.Read },
  },
  {
    path: '/media',
    translationKey: 'media',
    component: MediaList,
    ability: { subject: PolicySubject.Media, action: PolicyAction.Read },
  },
  {
    path: '/media/add',
    translationKey: 'add',
    component: MediaUpload,
    ability: { subject: PolicySubject.Media, action: PolicyAction.Create },
  },
  {
    path: '/stats',
    translationKey: 'statistics',
    component: StatsDashboard,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
  },
  {
    path: '/stats/documents',
    translationKey: 'documents',
    component: StatsDocuments,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
  },
  {
    path: '/stats/documents/:uuid',
    translationKey: 'document',
    component: StatsDocuments,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
  },
  {
    path: '/stats/submitters',
    translationKey: 'submitters',
    component: StatsSubmitters,
    ability: { subject: PolicySubject.StatsHub, action: PolicyAction.Read },
  },
  {
    path: '/stats/submitters/:uuid',
    translationKey: 'submitters',
    component: StatsSubmitters,
    ability: { subject: PolicySubject.StatsHub, action: PolicyAction.Read },
  },
  {
    path: '/stats/streams',
    translationKey: 'streams',
    component: StatsStreams,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
  },
  {
    path: '/stats/streams/:uuid',
    translationKey: 'stream',
    component: StatsStreams,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
  },
  {
    path: '/stats/hubs',
    translationKey: 'hubs',
    component: StatsHubs,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
  },
  {
    path: '/stats/hubs/:uuid',
    translationKey: 'hub',
    component: StatsHubs,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
  },
  {
    path: '/stats/links',
    translationKey: 'link-groups',
    component: StatsLinkGroups,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
  },
  {
    path: '/stats/links/:uuid',
    translationKey: 'links',
    component: StatsLinks,
    ability: { subject: PolicySubject.Stats, action: PolicyAction.Read },
  },
  {
    path: '/settings',
    translationKey: 'settings',
    component: Settings,
  },
  {
    path: '/settings/organisation',
    translationKey: 'organisation',
    component: OrganisationList,
    ability: { subject: PolicySubject.Organisation, action: PolicyAction.Read },
  },
  {
    path: '/settings/organisation/:uuid',
    translationKey: 'detail',
    component: OrganisationDetail,
    ability: { subject: PolicySubject.Organisation, action: PolicyAction.Read },
  },
  {
    path: '/settings/user',
    translationKey: 'user',
    component: UserList,
    ability: { subject: PolicySubject.User, action: PolicyAction.Read },
  },
  {
    path: '/settings/user/:uuid',
    translationKey: 'detail',
    component: UserDetail,
    ability: { subject: PolicySubject.User, action: PolicyAction.Read },
  },
  {
    path: '/settings/hub',
    translationKey: 'hub',
    component: HubList,
    ability: { subject: PolicySubject.Hub, action: PolicyAction.Read },
  },
  {
    path: '/settings/hub/:uuid',
    translationKey: 'detail',
    component: HubDetail,
    ability: { subject: PolicySubject.Hub, action: PolicyAction.Read },
  },
  {
    path: '/settings/role',
    translationKey: 'role',
    component: RoleList,
    ability: { subject: PolicySubject.Role, action: PolicyAction.Read },
  },
  {
    path: '/settings/role/:uuid',
    translationKey: 'detail',
    component: RoleDetail,
    ability: { subject: PolicySubject.Role, action: PolicyAction.Read },
  },
  {
    path: '/settings/author',
    translationKey: 'authors',
    component: AuthorList,
    ability: { subject: PolicySubject.Author, action: PolicyAction.Read },
  },
  {
    path: '/settings/author/:uuid',
    translationKey: 'detail',
    component: AuthorDetail,
    ability: { subject: PolicySubject.Author, action: PolicyAction.Read },
  },
  {
    path: '/settings/documenttemplate',
    translationKey: 'templates',
    component: DocumentTemplateList,
    ability: { subject: PolicySubject.DocumentTemplate, action: PolicyAction.Read },
  },
  {
    path: '/settings/documenttemplate/:uuid',
    translationKey: 'detail',
    component: DocumentTemplateDetail,
    ability: { subject: PolicySubject.DocumentTemplate, action: PolicyAction.Read },
  },
  {
    path: '/settings/documentlayout',
    translationKey: 'layouts',
    component: DocumentLayoutList,
    ability: { subject: PolicySubject.DocumentLayout, action: PolicyAction.Read },
  },
  {
    path: '/settings/documentlayout/:uuid',
    translationKey: 'detail',
    component: DocumentLayoutDetail,
    ability: { subject: PolicySubject.DocumentLayout, action: PolicyAction.Read },
  },
  {
    path: '/settings/tag',
    translationKey: 'tags',
    component: TagList,
    ability: { subject: PolicySubject.Tag, action: PolicyAction.Read },
  },
  {
    path: '/settings/tag/:uuid',
    translationKey: 'detail',
    component: TagDetail,
    ability: { subject: PolicySubject.Tag, action: PolicyAction.Read },
  },
  {
    path: '/settings/inputfilter',
    translationKey: 'input-filter',
    component: InputFilterList,
    ability: { subject: PolicySubject.InputFilter, action: PolicyAction.Read },
  },
  {
    path: '/settings/inputfilter/:uuid',
    translationKey: 'detail',
    component: InputFilterDetail,
    ability: { subject: PolicySubject.InputFilter, action: PolicyAction.Read },
  },
  {
    path: '/settings/importer',
    translationKey: 'importer',
    component: ImporterList,
    ability: { subject: PolicySubject.Importer, action: PolicyAction.Read },
  },
  {
    path: '/settings/importer/:uuid',
    translationKey: 'detail',
    component: ImporterDetail,
    ability: { subject: PolicySubject.Importer, action: PolicyAction.Read },
  },
  {
    path: '/settings/config',
    translationKey: 'config',
    component: ConfigList,
    ability: { subject: PolicySubject.Config, action: PolicyAction.Read },
  },
  {
    path: '/settings/config/:uuid',
    translationKey: 'detail',
    component: ConfigDetail,
    ability: { subject: PolicySubject.Config, action: PolicyAction.Read },
  },
  {
    path: '/adbox',
    translationKey: 'adbox',
    component: AdboxList,
    ability: { subject: PolicySubject.Adbox, action: PolicyAction.Read },
  },
  {
    path: '/adbox/:uuid/content',
    translationKey: 'adbox-content',
    component: AdboxContent,
    ability: { subject: PolicySubject.Adbox, action: PolicyAction.Update },
  },
  {
    path: '/adbox/:uuid',
    translationKey: 'detail',
    component: AdboxDetail,
    ability: { subject: PolicySubject.Adbox, action: PolicyAction.Read },
  },
  {
    path: '/settings/adboxtemplate',
    translationKey: 'adbox-templates',
    component: AdboxTemplateList,
    ability: { subject: PolicySubject.AdboxTemplate, action: PolicyAction.Read },
  },
  {
    path: '/settings/adboxtemplate/:uuid',
    translationKey: 'detail',
    component: AdboxTemplateDetail,
    ability: { subject: PolicySubject.AdboxTemplate, action: PolicyAction.Read },
  },
  {
    path: '/changelog',
    translationKey: 'changelog',
    component: Changelog,
  },
];

export default routes;
