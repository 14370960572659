import React from 'react';
import { CButton, CCol, CContainer, CRow } from '@coreui/react';
import StackTrace from 'stacktrace-js';
import { environment } from './environments/environment';

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info) {
    this.setState({ hasError: true });

    const stackTrace = StackTrace.fromError(error, { offline: true });
    stackTrace.then((frames) => {
      StackTrace.report(frames, `${environment.apiHost}/js-error`, error.message);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={6}>
                <span className="clearfix">
                  <h1 className="float-start display-3 me-4">500</h1>
                  <h4 className="pt-3">Houston, we have a problem!</h4>
                  <p className="text-medium-emphasis float-start">The page you are looking for is temporarily unavailable.</p>
                </span>
                <CButton color="link" onClick={() => window.location.reload()}>
                  Reload
                </CButton>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
