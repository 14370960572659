import { Expose, Type } from 'class-transformer';
import { UserEntity } from '../user';

export class CredentialsEntity {
  @Expose()
  token: string;

  @Expose()
  @Type(() => UserEntity)
  user: UserEntity;
}
