import { IsNotEmpty, IsOptional } from 'class-validator';

export class HubSettingsDto {
  @IsNotEmpty()
  readonly key: string;
  @IsOptional()
  readonly allowMultipleStreams?: boolean;
  @IsOptional()
  readonly notificationUrl?: string;
}
