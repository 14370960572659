import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAbility } from '@casl/react';
import { CBadge, CNavItem } from '@coreui/react';
import { NavInterface } from '../_nav';
import { AbilityContext } from '../context/ability.context';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

export const AppSidebarNav = ({ items }) => {
  const location = useLocation();
  const userAbility = useAbility(AbilityContext);
  const { t } = useTranslation('navigation');

  const navLink = (name: string, icon: string, badge: any = null) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  const navItem = (item: NavInterface, index) => {
    const { component, translationKey, badge, icon, ability, className, ...rest } = item;
    const Component = component;

    if (!isEmpty(ability)) {
      if (!userAbility.can(ability.action, ability.subject)) {
        return null;
      }
    }

    const end = rest.end;
    delete rest.end;

    if (rest.to && !rest.items) {
      return (
        <NavLink end={end} className={`nav-link ${className}`} key={index} to={rest.to}>
          {navLink(t(translationKey), icon, badge)}
        </NavLink>
      );
    }
    return (
      <Component className={className} key={index}>
        {navLink(t(translationKey), icon, badge)}
      </Component>
    );
  };
  const navGroup = (item: NavInterface, index) => {
    const { component, translationKey, icon, to, ability, ...rest } = item;
    const Component = component;

    if (!isEmpty(ability)) {
      if (!userAbility.can(ability.action, ability.subject)) {
        return null;
      }
    }

    return (
      <Component idx={String(index)} key={index} toggler={navLink(t(translationKey), icon)} visible={location.pathname.startsWith(to)}>
        {item.items?.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
      </Component>
    );
  };

  return <React.Fragment>{items && items.map((item: NavInterface, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}</React.Fragment>;
};

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
