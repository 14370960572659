import { Expose } from 'class-transformer';
import { IsOptional } from 'class-validator';

export class StreamDto {
  @Expose()
  @IsOptional()
  uuid?: string;
  @Expose()
  title: string;
  @Expose()
  description: string;
  @Expose()
  @IsOptional()
  isDemo?: boolean;
  @Expose()
  isActive: boolean;
  @Expose()
  checkStream: boolean;
  @Expose()
  trackingType: string;
  @Expose()
  timeSpentTrackingType: string;
  @Expose()
  exportClass: string;
  @Expose()
  pingUrl: string;
  @Expose()
  replyUrl: string;
  @Expose()
  hubUuid: string;
}
