import { Expose, Type } from 'class-transformer';
import { TagContentEntity } from './tag.content.entity';

export class TagEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  @Type(() => TagContentEntity)
  content: TagContentEntity[];
}
