import store, { RootState } from './store/index';
import axios, { AxiosResponse } from 'axios';
import { environment } from './environments/environment';
import { isEmpty } from 'lodash';
import { downloadABlob } from 'better-file-downloader';

/*
axios.interceptors.response.use(
  function (response) {
    if (
      !isEmpty(response) &&
      !isEmpty(response.data) &&
      has(response.data, 'status')
    ) {
      if (response.data.status !== 'success') {
        const message = !isEmpty(response.data.message)
          ? response.data.message
          : 'Es ist ein interner Fehler aufgetreten.';
        store.dispatch(showError(message));
      }
    }
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(showError('Zugriff verweigert.'));
    } else {
      store.dispatch(showError('Es ist ein interner Fehler aufgetreten.'));
    }
    return Promise.reject(error);
  }
);*/

function generateRequestUrl(path: string) {
  const url = new URL('/api' + path, environment.apiHost);
  return url.toString();
}

export const setApiHeaders = (headers, { getState }) => {
  const credentials = (getState() as RootState).credentials;
  if (!isEmpty(credentials.token)) {
    headers.set('authorization', `Bearer ${credentials.token}`);
    if (!isEmpty(credentials.contextOrga)) {
      headers.set('x-organisation', credentials.contextOrga.uuid);
    }
  }
  return headers;
};

export function generateRequestHeaders() {
  const credentials = store.getState().credentials;
  // eslint-disable-next-line prefer-const
  let header = {};
  if (!isEmpty(credentials.user)) {
    header['Authorization'] = 'Bearer ' + credentials.token;
    if (!isEmpty(credentials.contextOrga)) {
      header['x-organisation'] = credentials.contextOrga.uuid;
    }
  }
  return header;
}

export function get(path: string): Promise<AxiosResponse<any>> {
  const url = generateRequestUrl(path);
  return axios.get(url, { headers: generateRequestHeaders() });
}

export function post(path: string, data: any = null): Promise<AxiosResponse<any>> {
  const url = generateRequestUrl(path);
  return axios.post(url, data, { headers: generateRequestHeaders() });
}

export function put(path: string, data: any = null): Promise<AxiosResponse<any>> {
  const url = generateRequestUrl(path);
  return axios.put(url, data, { headers: generateRequestHeaders() });
}

export function del(path: string): Promise<AxiosResponse<any>> {
  const url = generateRequestUrl(path);
  return axios.delete(url, { headers: generateRequestHeaders() });
}

export async function download(path: string, filetype: string, filename: string, extension: string, data: any = null) {
  const url = generateRequestUrl(path);
  const response = await axios.post(url, data, { headers: generateRequestHeaders(), responseType: 'blob' });
  await downloadABlob(response.data, {
    fileName: filename,
    extension: '',
    contentType: filetype,
  });
}
