import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { HubModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const streamApi = createApi({
  reducerPath: 'streamApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/hub/stream',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({}),
});

export const {} = streamApi;
