import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import { useContext, useRef, useState } from 'react';
import { MediaListComponent, MediaUploadComponent } from '.';
import AlertContext from '../AppAlertProvider';
import { MediaUploadComponentHandle } from './MediaUploadComponent';
import { MediaModel } from '../../models';
import { useTranslation } from 'react-i18next';

interface MediaSelectModalProps {
  onSelect: (media: MediaModel) => void;
  onDismiss: () => void;
  visible: boolean;
}

const MediaSelectModal = (props: MediaSelectModalProps) => {
  const { onSelect, onDismiss, visible } = props;

  const alert = useContext(AlertContext);
  const uploadRef = useRef<MediaUploadComponentHandle>(null);
  const [showUploadComponent, setShowUploadComponent] = useState(false);

  const { t } = useTranslation('media');
  const { t: tGeneral } = useTranslation();

  return (
    <CModal fullscreen={true} visible={visible} onClose={() => onDismiss()}>
      <CModalHeader>{showUploadComponent ? tGeneral('upload.message', { type: t('media') }) : tGeneral('select.message', { type: t('media') })}</CModalHeader>
      <CModalBody>
        {showUploadComponent ? (
          <>
            <p>
              <MediaUploadComponent
                onUploadSuccess={() => {
                  alert.showSuccess(tGeneral('uploaded.success', { type: t('files') }));
                  setShowUploadComponent(false);
                }}
                onUploadError={() => {}}
                ref={uploadRef}
              />
            </p>
            <p style={{ textAlign: 'center' }}>
              <CButton color="primary" onClick={() => uploadRef.current.uploadFiles()}>
                Hochladen
              </CButton>
            </p>
          </>
        ) : (
          <MediaListComponent onSelect={(media) => onSelect(media)} />
        )}
      </CModalBody>
      <CModalFooter style={{ textAlign: 'right' }}>
        <CButton onClick={() => setShowUploadComponent(!showUploadComponent)} color="primary">
          {showUploadComponent ? tGeneral('select.message', { type: t('media') }) : tGeneral('upload.message', { type: t('media') })}
        </CButton>
        <CButton onClick={() => onDismiss()} color="secondary">
          {tGeneral('cancel')}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default MediaSelectModal;
