import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { OrganisationEntity } from '../organisation';

export class AuthorEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title?: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  @Transform(({ obj }) => {
    return `${obj.title ? obj.title + ' ' : ''}${obj.firstName} ${obj.lastName}`;
  })
  fullName?: string;

  @Expose()
  description?: string;

  @Expose()
  isActive?: boolean;

  @Expose()
  @Type(() => OrganisationEntity)
  organisation?: OrganisationEntity;

  @Expose()
  createdAt?: Date;

  @Expose()
  updatedAt?: Date;

  @Expose()
  deletedAt?: Date;
}

export class ExternalAuthorEntity extends AuthorEntity {
  @Exclude()
  isActive?: boolean;

  @Exclude()
  organisation?: OrganisationEntity;
}
