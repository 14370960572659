import { Exclude, Expose, Type } from 'class-transformer';
import { DocumentTemplateEntity } from '../documenttemplate';
import { OrganisationEntity } from '../organisation';
import { StreamEntity } from '../stream';

export class DocumentLayoutEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  description?: string;

  @Expose()
  content?: string;

  @Expose()
  css?: string;

  @Expose()
  layoutClass?: string;

  @Expose()
  @Type(() => DocumentTemplateEntity)
  documentTemplates?: DocumentTemplateEntity[];

  @Expose()
  @Type(() => StreamEntity)
  streams?: StreamEntity[];

  @Expose()
  @Type(() => OrganisationEntity)
  organisation: OrganisationEntity;
}

export class ExternalDocumentLayoutEntity extends DocumentLayoutEntity {
  @Exclude()
  layoutClass?: string;

  @Exclude()
  css?: string;

  @Exclude()
  content?: string;

  @Exclude()
  documentTemplates?: DocumentTemplateEntity[];

  @Exclude()
  streams?: StreamEntity[];

  @Exclude()
  organisation: OrganisationEntity;
}
