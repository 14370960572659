/* eslint-disable @typescript-eslint/ban-types */
import { isEmpty } from 'lodash';
import React from 'react';

interface AppSeperatorProps {
  text?: string;
  toggleHandler?: Function;
  toggleStatus?: boolean;
}

const AppSeperator = (props: AppSeperatorProps) => {
  const { text, toggleHandler, toggleStatus } = props;
  const downArrow = '↓';
  const upArrow = '↑';

  if (!isEmpty(text)) {
    const currentArrow = toggleStatus ? upArrow : downArrow;
    return (
      <hr
        style={{ cursor: typeof toggleHandler === 'function' ? 'pointer' : 'inherit' }}
        className="hr-text"
        data-content={toggleHandler ? text + ' ' + currentArrow : text}
        onClick={() => {
          if (typeof toggleHandler === 'function') {
            toggleHandler();
          }
        }}
      />
    );
  }

  return <hr />;
};

export default React.memo(AppSeperator);
