import { IsNotEmpty } from 'class-validator';
import { AdboxTemplateFilterDto } from './adboxtemplatefilter.dto';
import { AdboxTemplateSlotDto } from './adboxtemplateslot.dto';

export class AdboxTemplateDto {
  readonly uuid?: string;
  @IsNotEmpty()
  readonly title: string;
  readonly description?: string;
  readonly previewImageUuid?: string;
  readonly html: string;
  readonly withoutOrganisation?: boolean;
  readonly organisationUuid?: string;
  readonly slots: AdboxTemplateSlotDto[];
  readonly filter?: AdboxTemplateFilterDto[];
  readonly author?: string;
}
