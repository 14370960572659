import { DocumentTemplateDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { DocumentTemplateModel, DocumentTemplateSlotModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const documentTemplateApi = createApi({
  reducerPath: 'documentTemplateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/documenttemplate',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    createDocumentTemplate: builder.query<DocumentTemplateModel, DocumentTemplateDto>({
      query: (dto) => ({
        method: 'POST',
        url: '',
        body: dto,
      }),
    }),
    getDocumentTemplate: builder.query<DocumentTemplateModel, string>({
      query: (uuid) => uuid,
      keepUnusedDataFor: 0,
    }),
    updateDocumentTemplate: builder.query<DocumentTemplateModel, { uuid: string; dto: DocumentTemplateDto }>({
      query: ({ uuid, dto }) => ({
        method: 'PUT',
        url: uuid,
        body: dto,
      }),
    }),
    deleteDocumentTemplate: builder.query<DocumentTemplateModel, string>({
      query: (uuid) => ({
        method: 'DELETE',
        url: uuid,
      }),
    }),
    getDocumentTemplateSlot: builder.query<DocumentTemplateSlotModel, string>({
      query: (uuid) => uuid,
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyCreateDocumentTemplateQuery,
  useGetDocumentTemplateQuery,
  useLazyGetDocumentTemplateQuery,
  useLazyUpdateDocumentTemplateQuery,
  useLazyDeleteDocumentTemplateQuery,
  useLazyGetDocumentTemplateSlotQuery,
} = documentTemplateApi;
