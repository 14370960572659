import { Expose, Type } from 'class-transformer';
import { OrganisationEntity } from '../organisation';
import { UserEntity } from '../user';
import { AdboxTemplateEntity } from './adbox-template.entity';
import type { AdboxTemplateEntity as AdboxTemplateEntityType } from './adbox-template.entity';
import { AdboxImporterEntity } from './adboximporter.entity';
import { AdboxTemplateSlotEntity } from './adboxtemplateslot.entity';

export class AdboxCustomizationEntity {
  @Expose()
  @Type(() => AdboxTemplateSlotEntity)
  slot: AdboxTemplateSlotEntity;

  @Expose()
  value: string;
}

export class AdboxEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  @Type(() => AdboxTemplateEntity)
  template: AdboxTemplateEntityType;

  @Expose()
  @Type(() => UserEntity)
  user: UserEntity;

  @Expose()
  @Type(() => AdboxCustomizationEntity)
  customizations?: AdboxCustomizationEntity[];

  @Expose()
  @Type(() => OrganisationEntity)
  organisation: OrganisationEntity;

  @Expose()
  @Type(() => AdboxImporterEntity)
  importer: AdboxImporterEntity;

  @Expose()
  createdAt?: Date | string;

  @Expose()
  updatedAt?: Date | string;

  @Expose()
  deletedAt?: Date | string;

  @Expose()
  publishedAt?: Date | string;
}
