import { CImage, CSidebar, CSidebarBrand, CSidebarFooter, CSidebarHeader, CSidebarNav, CSidebarToggler } from '@coreui/react';
import { AppSidebarNav } from './AppSidebarNav';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

// sidebar nav config
import navigation from '../_nav';
import { useAppDispatch, useAppSelector } from '../store';
import { setVisible, toggleUnfoldable } from '../store/slices/uiSlice';

import newscubeWuerfel from '../assets/newscube_wuerfel_rgb.png';
import newscubeLogo from '../assets/newscube_logo_rgb.png';
import { useState } from 'react';

const AppSidebar = () => {
  const dispatch = useAppDispatch();

  const unfoldable = useAppSelector((state) => state.ui.unfoldable);
  const sidebarShow = useAppSelector((state) => state.ui.sidebar);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(setVisible({ visible }));
      }}
      colorScheme="dark"
    >
      <CSidebarHeader className="justify-center">
        <CSidebarBrand className="d-none d-md-flex">
          {unfoldable ? <CImage src={newscubeWuerfel} style={{ maxHeight: '32px' }} /> : <CImage src={newscubeLogo} style={{ maxHeight: '32px' }} />}
        </CSidebarBrand>
      </CSidebarHeader>
      <CSidebarNav>
        <AppSidebarNav items={navigation} />
      </CSidebarNav>
    </CSidebar>
  );
};

export default AppSidebar;
