import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next
  .use({
    type: 'backend',
    read(language: string, namespace: string, callback: (errorValue: unknown, translations: null | string) => void) {
      import(`./locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    },
  })
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      convertDetectedLanguage: (lng) => lng.split('-')[0],
    },
    fallbackLng: 'de',
    supportedLngs: ['de', 'en'],
    interpolation: {
      escapeValue: false,
    },
    appendNamespaceToCIMode: true,
    debug: process.env.NODE_ENV === 'development',
    nsSeparator: '.',
    defaultNS: false,
    ns: [
      'adbox',
      'author',
      'breadcrumb',
      'config',
      'dashboard',
      'editor',
      'hub',
      'importer',
      'input-filter',
      'layout',
      'links',
      'media',
      'navigation',
      'organisation',
      'role',
      'stats',
      'tag',
      'template',
      'translation',
      'user',
      'error',
      'changelog',
    ],
    initImmediate: true,
  });

export default i18next;
