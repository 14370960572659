import { AuthorDto } from '@nccore/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiHeaders } from '../../api';
import { environment } from '../../environments/environment';
import { AuthorModel } from '../../models';

// Define a service using a base URL and expected endpoints
export const authorApi = createApi({
  reducerPath: 'authorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiHost + '/author',
    prepareHeaders: setApiHeaders,
  }),
  endpoints: (builder) => ({
    getAuthor: builder.query<AuthorModel, string>({
      query: (uuid) => ({ method: 'GET', url: `${uuid}` }),
      keepUnusedDataFor: 0,
    }),
    createAuthor: builder.query<AuthorModel, AuthorDto>({
      query: (dto) => ({ method: 'POST', url: '', body: dto }),
    }),
    updateAuthor: builder.query<AuthorModel, { uuid: string; dto: AuthorDto }>({
      query: ({ uuid, dto }) => ({ method: 'PUT', url: `${uuid}`, body: dto }),
    }),
    deleteAuthor: builder.query<AuthorModel, string>({
      query: (uuid) => ({ method: 'DELETE', url: `${uuid}` }),
    }),
  }),
});

export const { useGetAuthorQuery, useLazyCreateAuthorQuery, useLazyUpdateAuthorQuery, useLazyDeleteAuthorQuery } = authorApi;
