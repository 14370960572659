import { Expose, Type } from 'class-transformer';
import { HubEntity } from '../hub';

export class StreamEntity {
  @Expose()
  uuid?: string;

  @Expose()
  title: string;

  @Expose()
  description: string;

  @Expose()
  isActive: boolean;

  @Expose()
  isDemo?: boolean;

  @Expose()
  checkStream: boolean;

  @Expose()
  trackingType: string;

  @Expose()
  timeSpentTrackingType: string;

  @Expose()
  exportClass: string;

  @Expose()
  pingUrl: string;

  @Expose()
  replyUrl: string;

  @Expose()
  @Type(() => HubEntity)
  hub: HubEntity;

  static trackingTypeOptions: Array<{ value: string; title: string }> = [
    { value: 'disabled', title: 'Deaktiviert' },
    { value: 'media', title: 'Media' },
    { value: 'js', title: 'Javascript' },
    { value: 'html', title: 'HTML' },
  ];

  static timeSpentTrackingTypeOptions: Array<{ value: string; title: string }> = [
    { value: 'disabled', title: 'Deaktiviert' },
    { value: 'socket', title: 'Socket' },
    { value: 'js', title: 'JavaScript' },
  ];

  @Expose()
  lastRequest?: Date;
}
