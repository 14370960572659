import 'react-app-polyfill/stable';
import 'core-js';
import { I18nextProvider } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import App from './App';
import { unregister } from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import { AlertProvider } from './components';
import { socket, subscribe, unsubscribe, SocketContext } from './context/socket.context';
import i18next from './i18n';
import ErrorBoundary from './ErrorBoundary';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <AlertProvider>
        <SocketContext.Provider value={{ socket, subscribe, unsubscribe }}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </SocketContext.Provider>
      </AlertProvider>
    </I18nextProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();
